import email from "../images/icons/email_icon.png";
import phone from "../images/icons/phone_icon.png";
import teams from "../images/icons/microsoft-teams-icon.png";
import handset from "../images/icons/phone-handset.svg";
import traveler from "../images/icons/traveler.png";
import download from "../images/icons/Download.svg";
import bravo from "../images/bravo-logo.png";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { getConfig } from '../utils/env-utils';
import { AuthenticateRequest } from './AuthenticateRequest';
import { TimeoutRequest } from './TimeoutRequest';


  const BASE_API_URL = `${getConfig('URL_ORG_API_BASE')}`;
  const URL_UI_ORIGIN = `${getConfig('URL_UI_ORIGIN')}`;

var hideSupplier = sessionStorage.getItem("supplierCheck");
var access_token = sessionStorage.getItem("access_token");
var currEmpl = sessionStorage.getItem("CURRENTEMPL");
var UserType = "X";
var SupplierIndicator = "X";

if (JSON.parse(currEmpl) && JSON.parse(currEmpl)[0] && JSON.parse(currEmpl)[0].UserType !== null)
{
  UserType = JSON.parse(currEmpl)[0].UserType;
  SupplierIndicator = JSON.parse(currEmpl)[0].SupplierIndicator;
}


export function DetailsModal(params) {
  // Declare a new state variable and call it "employeeData"
  const [employeeData, setEmployeeData] = useState([]);
  var empID = params.children[0];
  var color = params.children[1];

  ﻿var maxParticleCount = 150; var particleSpeed = 2; var startConfetti; var stopConfetti; var toggleConfetti; var removeConfetti; (function () {
    startConfetti = startConfettiInner; stopConfetti = stopConfettiInner; toggleConfetti = toggleConfettiInner; removeConfetti = removeConfettiInner; var colors = ["DodgerBlue", "OliveDrab", "Gold", "Pink", "SlateBlue", "LightBlue", "Violet", "PaleGreen", "SteelBlue", "SandyBrown", "Chocolate", "Crimson"]
    var streamingConfetti = false; var animationTimer = null; var particles = []; var waveAngle = 0; function resetParticle(particle, width, height) { particle.color = colors[(Math.random() * colors.length) | 0]; particle.x = Math.random() * width; particle.y = Math.random() * height - height; particle.diameter = Math.random() * 10 + 5; particle.tilt = Math.random() * 10 - 10; particle.tiltAngleIncrement = Math.random() * 0.07 + 0.05; particle.tiltAngle = 0; return particle; }
    function startConfettiInner() {
        var width = window.innerWidth; var height = window.innerHeight; window.requestAnimFrame = (function () { return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame || function (callback) { return window.setTimeout(callback, 16.6666667); }; })(); var canvas = document.getElementById("confetti-canvas"); if (canvas === null) { canvas = document.createElement("canvas"); canvas.setAttribute("id", "confetti-canvas"); canvas.setAttribute("style", "display:block;z-index:999999;pointer-events:none; position: absolute; top: 0px; width: 100%; height: 100%;"); document.body.appendChild(canvas); canvas.width = width; canvas.height = height; window.addEventListener("resize", function () { canvas.width = window.innerWidth; canvas.height = window.innerHeight; }, true); }
        var context = canvas.getContext("2d"); while (particles.length < maxParticleCount)
            particles.push(resetParticle({}, width, height)); streamingConfetti = true; if (animationTimer === null) {
                (function runAnimation() {
                    context.clearRect(0, 0, window.innerWidth, window.innerHeight); if (particles.length === 0)
                        animationTimer = null; else { updateParticles(); drawParticles(context); animationTimer = window.requestAnimFrame(runAnimation); }
                })();
            }
    }
    function stopConfettiInner() { streamingConfetti = false; }
    function removeConfettiInner() { stopConfetti(); particles = []; }
    function toggleConfettiInner() {
        if (streamingConfetti)
            stopConfettiInner(); else
            startConfettiInner();
    }
    function drawParticles(context) { var particle; var x; for (var i = 0; i < particles.length; i++) { particle = particles[i]; context.beginPath(); context.lineWidth = particle.diameter; context.strokeStyle = particle.color; x = particle.x + particle.tilt; context.moveTo(x + particle.diameter / 2, particle.y); context.lineTo(x, particle.y + particle.tilt + particle.diameter / 2); context.stroke(); } }
    function updateParticles() {
        var width = window.innerWidth; var height = window.innerHeight; var particle; waveAngle += 0.01; for (var i = 0; i < particles.length; i++) {
            particle = particles[i]; if (!streamingConfetti && particle.y < -15)
                particle.y = height + 100; else { particle.tiltAngle += particle.tiltAngleIncrement; particle.x += Math.sin(waveAngle); particle.y += (Math.cos(waveAngle) + particle.diameter + particleSpeed) * 0.5; particle.tilt = Math.sin(particle.tiltAngle) * 15; }
            if (particle.x > width + 20 || particle.x < -20 || particle.y > height) {
                if (streamingConfetti && particles.length <= maxParticleCount)
                    resetParticle(particle, width, height); else { particles.splice(i, 1); i--; }
            }
        }
    }
})();

  const lnkBravoClick = () => {
    startConfetti();
    setTimeout(function(){
      //redirect
      var mylink = document.getElementById("lnk_bravoReal");
      mylink.click();

      // try this ? 
      // let newTab = window.open();
      // newTab.location.href = url;

    }, 2000);
    setTimeout(function(){
      stopConfetti();
    }, 5000);
  }

  const lnkDownloadExportPPT = () => {
      fetch(
        BASE_API_URL + "/api/v1/OrgChartAPI/ExportOrgChartPPT/" +
          empID + "/" + hideSupplier,
        {
          method: "GET",
          headers: {
            'authorizationtoken': access_token,
            Origin: URL_UI_ORIGIN,
          },
        }
      )
      .then((res) => {
        if (res.status != 200){
          //window.location.href = "/Logout/Timeout";
          TimeoutRequest();
        }
        return res.blob();
      })
        .then((result) => {
          const url = window.URL.createObjectURL(
            new Blob([result]),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `OrgChartExport.pptx`,
          );
      
          // Append to html link element page
          document.body.appendChild(link);
      
          // Start download
          link.click();
      
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        })
        .catch((error) =>
          console.log("Error on the ExportOrgChart() fetch " + error)
        );
    };

    const lnkDownloadExportXLS = () => {
      console.log("click initatied");
      console.log(hideSupplier);
        fetch(
          BASE_API_URL + "/api/v1/OrgChartAPI/ExportOrgChartXLS/" +
            empID + "/" + hideSupplier,
          {
            method: "GET",
            headers: {
              'authorizationtoken': access_token,
              Origin: URL_UI_ORIGIN,
            },
          }
        )
        .then((res) => {
          if (res.status != 200){
            //window.location.href = "/Logout/Timeout";
            TimeoutRequest();
          }
          return res.blob();
        })
          .then((result) => {
            const url = window.URL.createObjectURL(
              new Blob([result]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `OrgChartExport.xlsx`,
            );
        
            // Append to html link element page
            document.body.appendChild(link);
        
            // Start download
            link.click();
        
            // Clean up and remove the link
            link.parentNode.removeChild(link);
          })
          .catch((error) =>
            console.log("Error on the ExportOrgChartXLS() fetch " + error)
          );
      };

    const lnkDownloadExportCSV = () => {
        console.log(hideSupplier);
          fetch(
            BASE_API_URL + "/api/v1/OrgChartAPI/ExportOrgChartCSV/" +
              empID + "/" + hideSupplier,
            {
              method: "GET",
              headers: {
                'authorizationtoken': access_token,
                Origin: URL_UI_ORIGIN,
              },
            }
          )
          .then((res) => {
            if (res.status != 200){
              //window.location.href = "/Logout/Timeout";
              TimeoutRequest();
            }
            return res.blob();
          })
            .then((result) => {
              const url = window.URL.createObjectURL(
                new Blob([result]),
              );
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute(
                'download',
                `OrgChartExport.csv`,
              );
          
              // Append to html link element page
              document.body.appendChild(link);
          
              // Start download
              link.click();
          
              // Clean up and remove the link
              link.parentNode.removeChild(link);
            })
            .catch((error) =>
              console.log("Error on the ExportOrgChartCSV() fetch " + error)
            );
        };

  const DisplayEmployeeDetailsClick = (value) => {
    fetch(
      BASE_API_URL + "/api/v1/OrgChartAPI/GetEmployeeOrg/" +
        empID + "/" + hideSupplier,
      {
        method: "GET",
        headers: {
          'authorizationtoken': access_token,
          Origin: URL_UI_ORIGIN,
        },
      }
    )
    .then((res) => {
      if (res.status != 200){
        //window.location.href = "/Logout/Timeout";
        TimeoutRequest();
      }
      return res.json();
    })
      .then((result) => {
        if (employeeData.length == 0) {
          setEmployeeData([result]);
          //console.log(result);
          
          AuthenticateRequest();
        }
        handleShow();
      })
      .catch((error) =>
        console.log("Error on the DisplayEmployeeDetailsClick() fetch " + error)
      );
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="white" onClick={DisplayEmployeeDetailsClick}>
        <i className={'fa fa-solid fa-circle-info fa-24px pointer ' + color}></i>
      </Button>

      <Modal
        className="my-modal modal-mobile-override"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>
            {employeeData.map((title) => (
              <div key={"div_titleModal_" + title.Counter}>{title.Name}</div>
            ))}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="no-side-padding">
          <div>
            {employeeData.map((detail) => (
              <div key={"div_modalBody" + detail.Counter}>
                <div className="modal-image-section empl-img-size-large">
                  <div className="empl-img-large">

                  {detail.PhotoString.length > 0 && (
                    <div className="image-cropper-large">
                      <img
                        src={detail.PhotoString}
                        alt=""
                        className="small-img"
                      />
                    </div>
                  )}

                  {detail.PhotoString.length == 0 && (
                    <div className="initial-cropper-large">
                      {detail.Initials}
                    </div>
                  )}

                  </div>
                  <div className="org-empl-pos-main padding-top-10px">
                    <span className="org-links-big-title">{detail.Title}</span>

                    {detail.DepartmentDescription !== "" && (
                      <span>
                        <br />
                        {detail.DepartmentDescription}
                      </span>
                    )}

                    {detail.Building !== "" && (
                      <span>
                        <br />
                        {detail.Building}, {detail.Location}
                      </span>
                    )}
                    {detail.Building === "" && 
                      <span>
                        <br />
                        {detail.Location}
                      </span>
                    }
                  </div>
                </div>

                <div className="org-details-modal">
                  <div className="org-links-big-modal">
                    <div>
                      <img src={email} alt="" className="org-icon-modal" />{" "} <strong>Email:</strong>{" "}
                      <a
                        className="org-links-big-modal"
                        href={"mailto:" + detail.Email}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {detail.Email}
                      </a>
                    </div>
                  </div>

                  <div className="org-links-big-modal">
                    <div>
                      <img src={handset} alt="" className="org-icon-modal" />{" "}
                      <strong>Work Phone:</strong>{" "}
                      <a
                        className="org-links-big-modal"
                        href={"tel:" + detail.WorkPhone}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {detail.WorkPhone}
                      </a>
                    </div>
                  </div>

                  <div className="org-links-big-modal">
                    <div>
                      <img src={phone} alt="" className="org-icon-modal" />{" "}
                      <strong>Mobile Phone:</strong>{" "}
                      <a
                        className="org-links-big-modal"
                        href={"tel:" + detail.MobilePhone}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {detail.MobilePhone}
                      </a>
                    </div>
                  </div>

                  {detail.SupplierIndicator === "Y" && detail.OrgUnitName !== "" && (
                    <div className="org-links-big-modal">
                      <strong>Supplier:</strong> <img src={traveler} alt="" className="org-icon-modal" />
                      <span>{detail.OrgUnitName}</span>
                    </div>
                  )}

                  {detail.Email !== "" && detail.ShowTeamsLinks === "Y" && (
                    <div className="org-links-big-modal">
                      <strong>Teams</strong>{" "}
                      <a
                        className="org-links-big-modal"
                        href={
                          "https://teams.microsoft.com/l/chat/0/0?users=" +
                          detail.Email
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={teams} alt="" className="org-icon-no-opac-modal" />
                        Chat
                      </a>
                      &nbsp;|&nbsp;
                      <a
                        className="org-links-big-modal"
                        href={
                          "https://teams.microsoft.com/l/call/0/0?users=" +
                          detail.Email
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Call
                      </a>
                    </div>
                  )}

                  {detail.SupplierIndicator !== "Y" && SupplierIndicator !== "Y" && (
                    <div className="org-links-big-modal bravo-div">
                      <a
                        className="org-links-big-modal no-underline pointer"
                        onClick={lnkBravoClick}
                        rel="noopener noreferrer"
                      >
                        Recognize {detail.FirstName} with a 
                        <img src={bravo} alt="" className="bravo-icon" />
                        award!
                      </a>
                      <a href="https://unitedrecognition.performnet.com/unitedrecognition/recognitionWizard/sendRecognitionDisplay.do" className="hide" id="lnk_bravoReal" target="_blank">Bravo</a>
                    </div>
                  )}
                </div>
                <div className="org-empl-modal-separator">
                  Other information
                </div>
                <div className="org-details-modal">

                  {detail.ManagerID !== "" && (
                    <div>
                      <span className="org-links-big-modal-label">Reports to:</span>{" "}
                      <a className="org-links-big-modal" href={detail.ManagerID}>
                        {detail.ManagerName}
                      </a>
                      <br />
                      <br />
                    </div>
                  )}

                  {detail.DirectReportList.length > 0 && (
                    <div>
                      <span className="org-links-big-modal-label">Direct reports: </span>{" "}
                      <span className="org-detail-direct-report-list">
                      {detail.DirectReportList.map((directReport, i) => (
                        <span key={directReport.ID}>
                          {i > 0 && ", "}
                          <a className="org-links-big-modal" href={directReport.ID}>
                            {directReport.Name}
                          </a>
                        </span>
                      ))}
                    </span>
                  </div>
                  )}
                </div>


                {detail.IsManagement === "Y" && UserType === "M" && (
                  <div className="org-details-modal pointer">
                    <span className="org-links-big-modal-label">Export {detail.FirstName}'s organization:&nbsp;</span>
                    <a className="org-links-export-modal pointer" onClick={lnkDownloadExportPPT}><img src={download} alt="" className="org-links-export-icon-modal" />.pptx</a>
                    &nbsp;|&nbsp;
                    <a className="org-links-export-modal pointer" onClick={lnkDownloadExportXLS}><img src={download} alt="" className="org-links-export-icon-modal" />.xlsx</a>
                    &nbsp;|&nbsp;
                    <a className="org-links-export-modal pointer" onClick={lnkDownloadExportCSV}><img src={download} alt="" className="org-links-export-icon-modal" />.csv</a>
                  </div>
                )}

              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DetailsModal;

import React, { Component } from "react";
import { getConfig } from '../utils/env-utils';

var OAM_CODE = "";
const BASE_API_URL = `${getConfig('URL_ORG_API_BASE')}`;
const URL_UI_ORIGIN = `${getConfig('URL_UI_ORIGIN')}`;
const OAM_BASE_AUTHORIZE_URL = `${getConfig('OAM_AUTHORIZE_URL')}`;
const OAM_TOKEN_URL = `${getConfig('OAM_TOKEN_URL')}`;
const OAM_TOKEN_INFO_URL = `${getConfig('OAM_TOKEN_INFO_URL')}`;
const OAM_OAUTH_IDENTITY_DOMAIN = `${getConfig('OAM_OAUTH_IDENTITY_DOMAIN')}`;
const OAM_CLIENT_ID = `${getConfig('OAM_CLIENT_ID')}`;
const OAM_CLIENT_NAME = `${getConfig('OAM_CLIENT_NAME')}`;
const OAM_CLIENT_AUTH = `${getConfig('OAM_CLIENT_AUTH')}`;
const OAM_REDIRECT_URI = `${getConfig('OAM_REDIRECT_URI')}`;
// const OAM_CODE_VERIFIER = `${getConfig('OAM_CODE_VERIFIER')}`;
// const OAM_CODE_CHALLENGE = `${getConfig('OAM_CODE_CHALLENGE')}`;
const OAM_CODE_VERIFIER = sessionStorage.getItem("codeVerifier");
const OAM_CODE_CHALLENGE = sessionStorage.getItem("codeChallenge");
const OAM_CODE_CHALLENGE_METHOD = `${getConfig('OAM_CODE_CHALLENGE_METHOD')}`;
const OAM_SCOPE = `${getConfig('OAM_SCOPE')}`;
const ENV = `${getConfig('ENV')}`;
const OAM_AUTHORIZE_URL = OAM_BASE_AUTHORIZE_URL + OAM_CODE_CHALLENGE;

var someText = "";

export async function RefreshToken() {
    //local
    if (ENV === 'local'){
        sessionStorage.setItem("access_token", "access_token");
        sessionStorage.setItem("refresh_token", "refresh_token");
        sessionStorage.setItem("APP_LOADED", 'Y');
        return 0;
    }

    //console.log("authenticate request initiated.");
    //console.log("referred by - " + document.referrer);
    var refresh_token = sessionStorage.getItem("refresh_token");
    var access_token = sessionStorage.getItem("access_token");
    var app_loaded = sessionStorage.getItem("APP_LOADED");
    if (app_loaded === null){
        app_loaded = 'N';
    }
    
    if (app_loaded === 'Y'){
        if (refresh_token !== null && refresh_token !== ""){
            //has refresh token. call refresh api and get updated access token
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Basic " + OAM_CLIENT_AUTH);
            myHeaders.append("x-oauth-identity-domain-name", OAM_OAUTH_IDENTITY_DOMAIN);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            //myHeaders.append("Cookie", "oam_int_devqa=rd4o00000000000000000000ffff0aa41d45o4443");

            var urlencoded = new URLSearchParams();
            urlencoded.append("refresh_token", refresh_token);
            urlencoded.append("grant_type", "REFRESH_TOKEN");
            urlencoded.append("redirect_uri", OAM_REDIRECT_URI);
            urlencoded.append("response_type", "token");
            urlencoded.append("code_verifier", OAM_CODE_VERIFIER);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };

            var retVal = await fetch(OAM_TOKEN_URL, requestOptions)
                .then(response => response.json())
                .then(result => {
                    var stringy = JSON.stringify(result);          
                    var jsonObj = JSON.parse(stringy);

                    access_token = jsonObj["access_token"];
                    refresh_token = jsonObj["refresh_token"];

                    sessionStorage.setItem("access_token", access_token);
                    sessionStorage.setItem("refresh_token", refresh_token);
                    sessionStorage.setItem("APP_LOADED", 'Y');
                    return "OK";
                })
                .catch(error => {
                    console.log('refresh token error', error);
                    //error validating refresh token. Login again
                    window.location.href = OAM_AUTHORIZE_URL;    
                });

        }
        else
        {
            console.log("in validatetoken - no refresh token");
            //no refresh token. so now we redirect back to login page
            window.location.href = OAM_AUTHORIZE_URL;
        }
    }
    else
    {
        console.log("APP NOT LOADED. DO LOGIC HERE OR WHAT?");
        if ((access_token == null || access_token == "") && (refresh_token == null || refresh_token == "")) {
            //nothing in session storage. redirect to login page
            window.location.href = OAM_AUTHORIZE_URL;
        }

    }
}
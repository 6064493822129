import "../App.css";
import "../js/fontawesome/css/all.css";
import "../css/site.css";
import "../css/landing.css";
import "../css/main.master.css";
import "../css/survey.css";
import "../css/admin-toolbar.css";
import "../css/content-box.css";
import "../css/united-colors.css";
import "../css/switches.css";
import "../css/administration.css";
import "../css/jquery-ui.css";
import "../js/slick/slick.css";
import "../js/slick/slick-theme.css";
import email from "../images/icons/Email_Icon.svg";
import handset from "../images/icons/office-phone.svg";
import phone from "../images/icons/Phone_Icon.svg";
import teams from "../images/icons/Teams_Icon.svg";
import React from "react";
import {OverlayTrigger, Popover } from 'react-bootstrap';
import $ from "jquery";
import div from "slick-carousel";
//import ActiveUserId from "../components/emplid.js";
import { DetailsModal } from "../components/DetailsModal";
import { getConfig } from '../utils/env-utils';
import { AuthenticateRequest } from "../components/AuthenticateRequest";
import { TimeoutRequest } from '../components/TimeoutRequest';

const BASE_API_URL = `${getConfig('URL_ORG_API_BASE')}`;
const URL_UI_ORIGIN = `${getConfig('URL_UI_ORIGIN')}`;

var currEmpl = sessionStorage.getItem("CURRENTEMPL");
var OnCurrentOrg = "";

if (JSON.parse(currEmpl) && JSON.parse(currEmpl)[0] && JSON.parse(currEmpl)[0].OnCurrentOrg !== null)
{
  OnCurrentOrg = JSON.parse(currEmpl)[0].OnCurrentOrg;
}


$(window).on('resize orientationchange', function() {
  $('.org-carousel-card').slick('resize');
});

const lnkRollupClick = event => {
  for(var rollupNum = 2; rollupNum < 99; rollupNum++) {
    var divid = "div_rollup_" + rollupNum;
    if ($("#" + divid).length){
      if ($("#div_rollup_" + rollupNum).hasClass("hide-rollup")){
        $("#div_rollup_" + rollupNum).removeClass("hide-rollup");
        
        //anything left
        var nextNum = rollupNum + 1;
        if (!$("#div_rollup_" + nextNum).length){
          $("#lnk_rollup").addClass("hide-rollup");
        }          
        rollupNum = 99;
      }
    }
    else
    {
      $("#lnk_rollup").addClass("hide-rollup");
    }
  }
};


class EmployeeComponent extends React.Component {
  constructor(props) {
    super(props); 
    this.state = { employees: [] };
  }

  
  componentDidMount() {
    var access_token = sessionStorage.getItem("access_token");
    var emplid = "";
    var hideSupplier = sessionStorage.getItem("supplierCheck");

    if (window.location.pathname !== "/OrgChart/") {
      emplid = window.location.pathname;
      var emplarr = emplid.split("/");
      emplid = emplarr[2];
    } else {
      //error. no employee passed
      window.location.href = "/";
    }
    fetch(
      BASE_API_URL + "/api/v1/OrgChartAPI/GetEmployeeOrg/" +
        emplid +
        "/" +
        hideSupplier,
      {
        method: "GET",
        headers: {
          'authorizationtoken': access_token,
          Origin: URL_UI_ORIGIN,
          "Content-Type": "application/json"
        },
      }
    )
      .then((res) => {   
        if (res.status != 200){
          //window.location.href = "/Logout/Timeout";
          TimeoutRequest();
        }
        return res.json();
      })
      .then((result) => {
        //console.log(JSON.stringify(result));
        var newData = this.state.employees.concat([result]);
        this.setState({ employees: newData });
        
        //refresh oam session
        AuthenticateRequest();
        
        $(".org-carousel-card").slick({
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: false,
          //variableWidth: true,
          // nextArrow: '.next-carousel-btn',
          // prevArrow: '.previous',
          responsive: [
            {
              breakpoint: 2280,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 1870,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 1466,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 1186,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 780,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        });
      })
      .catch((error) => {
        console.log("help: " + error);
      });
  }

  render() {
    return (
      <div>
        {this.state.employees.map((emp) => (
          <div key={emp.ID} className="main-wrap bg-direct-report-gray box-shadow-header">
            <div className="bg-gray-fix">
              <div className="container container-adj adjust-container-color">

                {emp.ManagerID != "" && (
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 text-center">
                      <a id="lnk_rollup" onClick={lnkRollupClick}>
                        <span className="up-org-arrow circle circle-md mr-2 up-button-mg pointer">
                          <i className="fa fa-angles-up"></i>
                        </span>
                      </a>
                    </div>
                  </div>
                )}
              
              {/* org rollup starts here */}
              {emp.OrgRollupList.length > 0 &&
                emp.OrgRollupList.map((rollup) => (
                  <div className="row hide-rollup" key={rollup.ID} id={"div_rollup_" + rollup.OrgOrder}>
                    <div className="col-xs-12 col-sm-12 text-center">
                      <div className="card main-card bg-white">
                        <div className="org-card-main">
                          <div className="org-top-half-white">
                            <div className="empl-img-size-large">
                              <div className="empl-img-large">
                                {rollup.PhotoString.length > 0 && (
                                  <div className="image-cropper-large">
                                    <img
                                    src={rollup.PhotoString}
                                    alt=""
                                    className="small-img"
                                    />
                                  </div>
                                )}

                                {rollup.PhotoString.length == 0 && (
                                  <div className="initial-cropper-large">
                                    {rollup.Initials}
                                  </div>
                                )}

                              </div>
                              <div className="org-empl-name-main">
                                <a className="org-empl-fullname-secondary" href={rollup.ID}>{rollup.Name}</a>
                                <div className="org-empl-pos-main">
                                {rollup.Title.length > 0 && (
                                  <div>
                                    {rollup.Title}
                                    <br />
                                  </div>
                                )}
                                {rollup.Building} {rollup.Room}
                                </div>
                              </div>
                            </div>

                            {OnCurrentOrg === "Y" && rollup.IsCostCenter === "N" && (
                              <div className="org-more-options">
                                <DetailsModal>{rollup.ID}{"dark-pointer-color"}</DetailsModal>
                              </div>
                            )}

                          </div>
                          <div
                            className="org-bottom-half-white"
                          >
                            <div className="direct-report-count">
                              {rollup.DirectReportCount > 0 && (
                                <a href={rollup.ID}>
                                  <span className="dd-badge-light circle circle-md mr-2">
                                    <span><i className="fa fa-user"></i> {rollup.DirectReportCount}</span>
                                  </span>
                                </a>
                              )}
                            </div>

                            {rollup.IsCostCenter === "Y" && rollup.CostCenterDisclaimer.length > 0 && rollup.CostCenterDisclaimer !== "" && (
                              <div className="org-links-cc-big">
                                <i>
                                  {rollup.CostCenterDisclaimer}
                                </i>
                              </div>
                            )}

                            {rollup.Email.length > 0 && (
                              <div>
                                <a
                                  className="org-links-big"
                                  href={"mailto:" + rollup.Email}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img src={email} alt="" className="org-icon" />{" "}
                                  {rollup.Email}
                                </a>
                                <br />
                                </div>
                            )}

                            {(rollup.WorkPhone.length > 0 && rollup.MobilePhone.length > 0) && (
                              <div>
                                <a
                                className="org-links-big"
                                href={"tel:" + rollup.WorkPhone}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={handset}
                                  alt=""
                                  className="org-icon"
                                />{" "}
                                {rollup.WorkPhone}
                                </a>
                                <a
                                  className="org-links-big"
                                  href={"tel:" + rollup.MobilePhone}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={phone}
                                    alt=""
                                    className="org-icon-2"
                                  />
                                  {rollup.MobilePhone}
                                </a>
                                <br />
                              </div>
                            )}

                            {(rollup.WorkPhone.length > 0 && rollup.MobilePhone.length == 0) && (
                              <div>
                                <a
                                className="org-links-big"
                                href={"tel:" + rollup.WorkPhone}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={handset}
                                  alt=""
                                  className="org-icon"
                                />{" "}
                                {rollup.WorkPhone}
                                </a>
                                <br />
                              </div>
                            )}

                            {(rollup.WorkPhone.length == 0 && rollup.MobilePhone.length > 0) && (
                              <div>
                                <a
                                  className="org-links-big"
                                  href={"tel:" + rollup.MobilePhone}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={phone}
                                    alt=""
                                    className="org-icon"
                                  />{" "}
                                  {rollup.MobilePhone}
                                </a>
                                <br />
                              </div>
                            )}

                            {OnCurrentOrg === "Y" && rollup.Email.length > 0 && rollup.ShowTeamsLinks == "Y" && (
                              <div className="font-14">
                                Teams:{" "}
                                <a
                                  className="org-links-big"
                                  href={
                                    "https://teams.microsoft.com/l/chat/0/0?users=" +
                                    rollup.Email
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={teams}
                                    alt=""
                                    className="org-icon-no-opac"
                                  />
                                  Chat
                                </a>
                                &nbsp;|&nbsp;
                                <a
                                  className="org-links-big"
                                  href={
                                    "https://teams.microsoft.com/l/call/0/0?users=" +
                                    rollup.Email
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Call
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <span className="that-line-main">
                        <span className="that-line-left"></span>
                        <span className="that-line-right"></span>
                      </span>

                    </div>
                  </div>
                ))}
                {/* org rollup ends here */}

                <div className="row">
                  <div className="col-xs-12 col-sm-12 text-center">
                    <div className="card main-card bg-white">
                      <div className="org-card-main">
                        <div className="org-top-half-blue">
                          <div className="empl-img-size-large">
                            <div className="empl-img-large">
                              {emp.PhotoString.length > 0 && (
                                <div className="image-cropper-large">
                                  <img
                                   src={emp.PhotoString}
                                   alt=""
                                   className="small-img"
                                  />
                                </div>
                               )}

                              {emp.PhotoString.length == 0 && (
                                <div className="initial-cropper-large">
                                  {emp.Initials}
                                </div>
                              )}


                            </div>
                            <div className="org-empl-name-main">
                              <b>{emp.Name}</b>
                              <div className="org-empl-pos-main">
                              {emp.Title.length > 0 && (
                                <div>
                                  {emp.Title}
                                  <br />
                                </div>
                              )}
                              {emp.Building} {emp.Room}
                              </div>
                            </div>
                          </div>

                          {OnCurrentOrg === "Y" && emp.IsCostCenter === "N" && (
                            <div className="org-more-options">
                              <DetailsModal>{emp.ID}{"adjust-pointer-color"}</DetailsModal>
                            </div>
                          )}

                        </div>
                        <div
                          className="org-bottom-half-white"
                        >
                          <div className="direct-report-count">
                            {emp.DirectReportCount > 0 && (
                              <span className="dd-badge-dark circle circle-md mr-2">
                                <span><i className="fa fa-user"></i> {emp.DirectReportCount}</span>
                              </span>
                            )}
                          </div>

                          {emp.IsCostCenter === "Y" && emp.CostCenterDisclaimer.length > 0 && emp.CostCenterDisclaimer !== "" && (
                              <div className="org-links-cc-big">
                                <i>
                                  {emp.CostCenterDisclaimer}
                                </i>
                              </div>
                            )}

                          {emp.Email.length > 0 && (
                            <div>
                              <a
                                className="org-links-big"
                                href={"mailto:" + emp.Email}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img src={email} alt="" className="org-icon" />{" "}
                                {emp.Email}
                              </a>
                              <br />
                              </div>
                          )}

                          {(emp.WorkPhone.length > 0 && emp.MobilePhone.length > 0) && (
                            <div>
                              <a
                              className="org-links-big"
                              href={"tel:" + emp.WorkPhone}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={handset}
                                alt=""
                                className="org-icon"
                              />{" "}
                              {emp.WorkPhone}
                              </a>
                              <a
                                className="org-links-big"
                                href={"tel:" + emp.MobilePhone}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={phone}
                                  alt=""
                                  className="org-icon-2"
                                />
                                {emp.MobilePhone}
                              </a>
                              <br />
                            </div>
                          )}

                          {(emp.WorkPhone.length > 0 && emp.MobilePhone.length == 0) && (
                            <div>
                              <a
                              className="org-links-big"
                              href={"tel:" + emp.WorkPhone}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={handset}
                                alt=""
                                className="org-icon"
                              />{" "}
                              {emp.WorkPhone}
                              </a>
                              <br />
                            </div>
                          )}

                          {(emp.WorkPhone.length == 0 && emp.MobilePhone.length > 0) && (
                            <div>
                              <a
                                className="org-links-big"
                                href={"tel:" + emp.MobilePhone}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={phone}
                                  alt=""
                                  className="org-icon"
                                />{" "}
                                {emp.MobilePhone}
                              </a>
                              <br />
                            </div>
                          )}

                          {OnCurrentOrg === "Y" && emp.Email.length > 0 && emp.ShowTeamsLinks == "Y" && (
                            <div className="font-14">
                              Teams:{" "}
                              <a
                                className="org-links-big"
                                href={
                                  "https://teams.microsoft.com/l/chat/0/0?users=" +
                                  emp.Email
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={teams}
                                  alt=""
                                  className="org-icon-no-opac"
                                />
                                Chat
                              </a>
                              &nbsp;|&nbsp;
                              <a
                                className="org-links-big"
                                href={
                                  "https://teams.microsoft.com/l/call/0/0?users=" +
                                  emp.Email
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Call
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {emp.DirectReportList.length > 0 &&
                      <span className="that-line-main">
                        <span className="that-line-left"></span>
                        <span className="that-line-right"></span>
                      </span>
                    } 
                    {emp.DirectReportList.length == 0 &&
                      <br />
                    } 
                  </div>
                </div>
              </div>
              
              {emp.DirectReportList.length > 0 &&
                <div className="org-direct-bg">
                  <div className="">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 text-center up-button-mg">
                        <div className="org-carousel-card text-center adjust-carousel-pad">
                          {emp.DirectReportList.length > 0 &&
                            emp.DirectReportList.map((DR) => (
                              <div key={DR.ID} className="org-direct-report">
                                <div className="card secondary-card bg-white">
                                  <div className="org-card-main">
                                    <div className="org-top-half-white">
                                      <div className="empl-img-size-medium">
                                        <div className="empl-img-medium">
                                          {DR.PhotoString.length > 0 && (
                                            <div className="image-cropper-medium">
                                            <img
                                              src={DR.PhotoString}
                                              alt=""
                                              className="width-100"
                                            />
                                            </div>
                                          )}
                                          
                                          {DR.PhotoString.length == 0 && (
                                            <div className="initials-cropper-medium">
                                              {DR.Initials}
                                            </div>
                                          )}
                                          
                                        </div>

                                        <div className="org-empl-name-secondary float-left">
                                          <a className="org-empl-fullname-secondary" href={DR.ID}>{DR.Name}</a>
                                          <div className="org-empl-pos-smaller-main">
                                            {DR.Title.length > 0 && (
                                              <div>
                                              {DR.Title}
                                              <br />
                                              </div>
                                            )}
                                            {DR.Building} {DR.Room}
                                          </div>
                                        </div>
                                      </div>


                                      {OnCurrentOrg === "Y" && DR.IsCostCenter === "N" && (
                                        <div className="org-more-options-secondary">
                                          <DetailsModal>{DR.ID}{"dark-pointer-color"}</DetailsModal>
                                        </div>
                                      )}

                                    </div>
                                    
                                    <div className="org-bottom-half-white adjust-padding-10">
                                      <div className="direct-report-count">
                                        {DR.DirectReportCount > 0 && (
                                          <a href={DR.ID}>
                                            <span className="dd-badge-light circle circle-md mr-2">
                                              <span><i className="fa fa-user"></i> {DR.DirectReportCount}</span>
                                            </span>                                            
                                          </a>
                                        )}
                                      </div>

                                      {DR.IsCostCenter === "Y" && DR.CostCenterDisclaimer.length > 0 && DR.CostCenterDisclaimer !== "" && (
                                          <div className="org-links-cc-big">
                                            <i>
                                              {DR.CostCenterDisclaimer}
                                            </i>
                                          </div>
                                        )}

                                      {DR.Email.length > 0 && (
                                        <div>
                                          <a
                                            className="org-links-big"
                                            href={"mailto:" + DR.Email}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <img
                                              src={email}
                                              alt=""
                                              className="org-icon"
                                            />{" "}
                                            {DR.Email}
                                          </a>
                                          <br />
                                        </div>
                                      )}

                                      {(DR.WorkPhone.length > 0 && DR.MobilePhone.length > 0) && (
                                        <div>
                                          <a
                                            className="org-links-big"
                                            href={"tel:" + DR.WorkPhone}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <img
                                              src={handset}
                                              alt=""
                                              className="org-icon"
                                            />{" "}
                                            {DR.WorkPhone}
                                          </a>
                                          <a
                                            className="org-links-big"
                                            href={"tel:" + DR.MobilePhone}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <img
                                              src={phone}
                                              alt=""
                                              className="org-icon-2 org-icon-inherit"
                                            />
                                            {DR.MobilePhone}
                                          </a>
                                          <br />
                                        </div>
                                      )}

                                      {(DR.WorkPhone.length > 0 && DR.MobilePhone.length == 0) && (
                                        <div>
                                          <a
                                            className="org-links-big"
                                            href={"tel:" + DR.WorkPhone}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <img
                                              src={handset}
                                              alt=""
                                              className="org-icon"
                                            />{" "}
                                            {DR.WorkPhone}
                                          </a>
                                          <br />
                                        </div>
                                      )}

                                      {(DR.WorkPhone.length === 0 && DR.MobilePhone.length > 0) && (
                                        <div>
                                          <a
                                            className="org-links-big"
                                            href={"tel:" + DR.MobilePhone}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <img
                                              src={phone}
                                              alt=""
                                              className="org-icon"
                                            />
                                            {DR.MobilePhone}
                                          </a>
                                          <br />
                                        </div>
                                      )}

                                      {OnCurrentOrg === "Y" && DR.Email.length > 0 && DR.ShowTeamsLinks == "Y" && (
                                        <div className="font-14">
                                          Teams:{" "}
                                          <a
                                            className="org-links-big"
                                            href={
                                              "https://teams.microsoft.com/l/chat/0/0?users=" +
                                              DR.Email
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <img
                                              src={teams}
                                              alt=""
                                              className="org-icon-no-opac"
                                            />
                                            Chat
                                          </a>
                                          &nbsp;|&nbsp;
                                          <a
                                            className="org-links-big"
                                            href={
                                              "https://teams.microsoft.com/l/call/0/0?users=" +
                                              DR.Email
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Call
                                          </a>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                {DR.DirectReportList.length > 0 &&
                                  DR.DirectReportList.map((DR2) => (
                                    <div key={DR2.ID}>
                                      <span className="that-line-main">
                                        <span className="that-line-left"></span>
                                        <span className="that-line-right"></span>
                                      </span>
                                      <div className="org-direct-report-next">
                                        <div className="card tertiary-card bg-white adjust-tertiary-card">
                                          <div className="org-card-main">
                                            <div className="org-top-half-white-tertriary">
                                              <div className="empl-img-size-medium">
                                                <div className="empl-img-medium">
                                                  {DR2.PhotoString.length > 0 && (
                                                    <div className="image-cropper-small">
                                                      <img
                                                        src={DR2.PhotoString}
                                                        alt=""
                                                        className="width-100"
                                                      />
                                                    </div>
                                                  )}

                                                  {DR2.PhotoString.length == 0 && (
                                                    <div className="initials-cropper-small">
                                                      {DR2.Initials}
                                                    </div>
                                                  )}
                                                </div>
                                                <div className="org-empl-name-tertriary text-left">

                                                  <a className="org-empl-fullname-secondary" href={DR2.ID}>
                                                    {DR2.Name.length > 20 && (
                                                      <span className="long-name-adj">
                                                        {DR2.Name}
                                                    </span>
                                                    )}
                                                    {DR2.Name.length <= 20 && (
                                                      <span>
                                                        {DR2.Name}
                                                      </span>
                                                    )}
                                                  </a>
                                                  <div className="org-empl-pos-smaller-main">
                                                    {DR2.Title.length > 0 && (
                                                      <div>
                                                        {DR2.Title}
                                                        <br />
                                                      </div>
                                                    )}
                                                    {DR2.Building} {DR2.Room}
                                                  </div>
                                                </div>
                                              </div>


                                              {OnCurrentOrg === "Y" && DR2.IsCostCenter === "N" && (
                                                <div className="org-more-options-tertiary">
                                                  <DetailsModal>
                                                    {DR2.ID}{"white-pointer-color"}
                                                  </DetailsModal>
                                                </div>
                                              )}

                                            </div>
                                            <hr className="no-margin" />
                                            <div className="org-bottom-half-white adjust-padding-10">
                                              <div className="direct-report-count">
                                                {DR2.DirectReportCount > 0 && (
                                                  <a href={DR2.ID}>
                                                    <span className="dd-badge-light circle circle-md mr-2">
                                                      <span><i className="fa fa-user"></i> {DR2.DirectReportCount}</span>
                                                    </span>                                                    
                                                  </a>
                                                )}
                                              </div>

                                              {DR2.IsCostCenter === "Y" && DR2.CostCenterDisclaimer.length > 0 && DR2.CostCenterDisclaimer !== "" && (
                                                <div className="org-links-cc-small">
                                                  <i>
                                                    {DR2.CostCenterDisclaimer}
                                                  </i>
                                                </div>
                                              )}

                                            {DR2.Email.length > 0 && (
                                              <div>
                                                <a
                                                  className="org-links-big"
                                                  href={"mailto:" + DR2.Email}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  <img
                                                    src={email}
                                                    alt=""
                                                    className="org-icon"
                                                  />{" "}
                                                  {DR2.Email}
                                                </a>
                                                <br />
                                              </div>
                                            )}

                                              {(DR2.WorkPhone.length > 0 && DR2.MobilePhone.length > 0) && (
                                                <div>
                                                  <a
                                                    className="org-links-big"
                                                    href={"tel:" + DR2.WorkPhone}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    <img
                                                      src={handset}
                                                      alt=""
                                                      className="org-icon"
                                                    />{" "}
                                                    {DR2.WorkPhone}
                                                  </a>
                                                  <a
                                                    className="org-links-big"
                                                    href={"tel:" + DR2.MobilePhone}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    <img
                                                      src={phone}
                                                      alt=""
                                                      className="org-icon-2 org-icon-inherit"
                                                    />
                                                    {DR2.MobilePhone}
                                                  </a>
                                                  <br />
                                                </div>
                                              )}

                                              
                                              {(DR2.WorkPhone.length > 0 && DR2.MobilePhone.length == 0) && (
                                                <div>
                                                  <a
                                                    className="org-links-big"
                                                    href={"tel:" + DR2.WorkPhone}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    <img
                                                      src={handset}
                                                      alt=""
                                                      className="org-icon"
                                                    />{" "}
                                                    {DR2.WorkPhone}
                                                  </a>
                                                  <br />
                                                </div>
                                              )}

                                              
                                              {(DR2.WorkPhone.length == 0 && DR2.MobilePhone.length > 0) && (
                                                <div>
                                                  <a
                                                    className="org-links-big"
                                                    href={"tel:" + DR2.MobilePhone}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    <img
                                                      src={phone}
                                                      alt=""
                                                      className="org-icon"
                                                    />{" "}
                                                    {DR2.MobilePhone}
                                                  </a>
                                                  <br />
                                                </div>
                                              )}

                                              {OnCurrentOrg === "Y" && DR2.Email.length > 0 && DR2.ShowTeamsLinks === "Y" && (
                                                <div className="font-14">
                                                  Teams:{" "}
                                                  <a
                                                    className="org-links-big"
                                                    href={
                                                      "https://teams.microsoft.com/l/chat/0/0?users=" +
                                                      DR2.Email
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    <img
                                                      src={teams}
                                                      alt=""
                                                      className="org-icon-no-opac"
                                                    />
                                                    Chat
                                                  </a>
                                                  &nbsp;|&nbsp;
                                                  <a
                                                    className="org-links-big"
                                                    href={
                                                      "https://teams.microsoft.com/l/call/0/0?users=" +
                                                      DR2.Email
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    Call
                                                  </a>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        ))}
      </div>
    );
  }
}


export default EmployeeComponent;

import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import $ from 'jquery';
//import ActiveUserId from "../components/emplid.js";
import { getConfig } from '../utils/env-utils';
import { TimeoutRequest } from '../components/TimeoutRequest';

import randomstring from "randomstring"
import CryptoJS from "crypto-js"

  const BASE_API_URL = `${getConfig('URL_ORG_API_BASE')}`;
  const URL_UI_ORIGIN = `${getConfig('URL_UI_ORIGIN')}`;
  const OAM_BASE_AUTHORIZE_URL = `${getConfig('OAM_AUTHORIZE_URL')}`;
  const OAM_TOKEN_URL = `${getConfig('OAM_TOKEN_URL')}`;
  const OAM_OAUTH_IDENTITY_DOMAIN = `${getConfig('OAM_OAUTH_IDENTITY_DOMAIN')}`;
  const OAM_CLIENT_ID = `${getConfig('OAM_CLIENT_ID')}`;
  const OAM_CLIENT_NAME = `${getConfig('OAM_CLIENT_NAME')}`;
  const OAM_CLIENT_AUTH = `${getConfig('OAM_CLIENT_AUTH')}`;
  const OAM_REDIRECT_URI = `${getConfig('OAM_REDIRECT_URI')}`;
  // const OAM_CODE_VERIFIER = `${getConfig('OAM_CODE_VERIFIER')}`;
  // const OAM_CODE_CHALLENGE = `${getConfig('OAM_CODE_CHALLENGE')}`;
  const OAM_CODE_CHALLENGE_METHOD = `${getConfig('OAM_CODE_CHALLENGE_METHOD')}`;
  const OAM_SCOPE = `${getConfig('OAM_SCOPE')}`;
  const ENV = `${getConfig('ENV')}`;

// var textString = '243721'; // Utf16-encoded string
// var words = CryptoJS.enc.Utf16.parse(textString); // WordArray object
// var base64 = CryptoJS.enc.Base64.stringify(words); // string: 'ADIANAAzADcAMgAx'
// alert(base64);
// var words = CryptoJS.enc.Base64.parse(base64);
// var textString2 = CryptoJS.enc.Utf16.stringify(words); // '243721'
// alert(textString2);

const OAM_CODE_VERIFIER = sessionStorage.getItem("codeVerifier");
const OAM_CODE_CHALLENGE = sessionStorage.getItem("codeChallenge");
const OAM_AUTHORIZE_URL = OAM_BASE_AUTHORIZE_URL + OAM_CODE_CHALLENGE;

class Redirect extends React.Component {
    constructor(props) {
      super(props);
      this.state={oam_response: []}
    }

    componentDidMount() {
      
      var current_url = window.location.href;
      var redirectIDArray = current_url.split("/");
      var employeeid = redirectIDArray[redirectIDArray.length - 1];
    
      if (sessionStorage.getItem("redirect_id") == null){
        sessionStorage.setItem("redirect_id", employeeid);
      }
      employeeid = sessionStorage.getItem("redirect_id");

      var access_token = sessionStorage.getItem("access_token");
      if (access_token !== null && access_token !== "")
      {
        if (employeeid !== null && employeeid !== "")
        {
          var words = CryptoJS.enc.Base64.parse(employeeid);
          var emplid = CryptoJS.enc.Utf8.stringify(words); // '243721'
          //console.log("employee id is ");
          //console.log(emplid);
          fetch(BASE_API_URL + '/api/v1/OrgChartAPI/GetEmployeeLink/' + emplid, {
            method: 'GET',
            headers: {
                'authorizationtoken': access_token,
                'Origin': URL_UI_ORIGIN,
                'Content-Type': 'application/json'
            },
          }).then((res) => {
            if (res.status != 200){
              //window.location.href = "/Logout/Timeout";
              TimeoutRequest();
            }
            return res.text();
          })
          .then(result => {
            sessionStorage.removeItem("redirect_id");
            window.location.href = "/OrgChart/" + result;
          })
          .catch(error => {
              console.log("error", error);
          });
        }
        
      }
      else
      {
        // no access token
        console.log("redirect to login page");
        window.location.href = "/";
      }
    }

    render() {
      return(
          <div>

          </div>
        );
      }
  }

export default Redirect
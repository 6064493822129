export const ORG_CONFIG = {
    'local': {
        ENV: 'local',
        URL_ORG_API_BASE: 'https://localhost:44355',
        URL_UI_ORIGIN: 'http://localhost:3000',
        OAM_AUTHORIZE_URL: 'https://signon-oqa.ual.com/oauth2/rest/authorize?client_id=DQR_Org_Chart_ODEV_CID&domain=DQR_Org_Chart_ODEV&redirect_uri=https://orgchart.dev.dqr.aws.ual.com/&code_challenge_method=S256&response_type=code&scope=openid%20profile%20DQR_Org_Chart_ODEV_RS01.All&code_challenge=',
        OAM_AUTHORIZE_REDIRECT_URL: 'https://signon-oqa.ual.com/oauth2/rest/authorize?client_id=DQR_Org_Chart_ODEV_CID&domain=DQR_Org_Chart_ODEV&code_challenge_method=S256&response_type=code&scope=openid%20profile%20DQR_Org_Chart_ODEV_RS01.All&code_challenge=',
        OAM_TOKEN_URL: 'https://signon-oqa.ual.com/oauth2/rest/token',
        OAM_TOKEN_INFO_URL: 'https://signon-oqa.ual.com/oauth2/rest/token/info',
        OAM_OAUTH_IDENTITY_DOMAIN: 'DQR_Org_Chart_ODEV',
        OAM_CLIENT_ID: 'DQR_Org_Chart_ODEV_CID',
        OAM_CLIENT_NAME: 'DQR_Org_Chart_ODEV_CNAME',
        OAM_CLIENT_AUTH: 'RFFSX09yZ19DaGFydF9PREVWX0NJRDphNjQ2ZGU2MzZjYmU0ZmI0OWE5OGM2MDM4MzhmMDM3OA==',
        OAM_REDIRECT_URI: 'https://orgchart.dev.dqr.aws.ual.com/',
        OAM_REDIRECT_DEEPLINK_URI: 'https://orgchart.dev.dqr.aws.ual.com/redirect/',
        // OAM_CODE_VERIFIER: 'ecaaf9ba8d2d092e92cf0d42818dfa77861d0ce6260720d89b77da02',
        // OAM_CODE_CHALLENGE: 'jyJGW3s73cvrVW4w7tMBV_E7mZFrGWOkp9ODKcHM2Vc',
        OAM_CODE_CHALLENGE_METHOD: 'S256',
        OAM_SCOPE: 'openid profile DQR_Org_Chart_ODEV_RS01.All',
        OAM_LOGOUT: 'https://signon-oqa.ual.com/oam/server/logout',
        DD_APP_ID: '88a6f5fa-7ff2-4f3e-9672-7dcacc882155',
        DD_CLIENT_TOKEN: 'pub80a2a41427970a790327423fbca0dcbc',
        DD_SERVICE: 'orgchart-web-dev',
        DD_ENV: 'dev'
    },
    'dev': {
        ENV: 'development',
        URL_ORG_API_BASE: 'https://orgchart-api.dev.dqr.aws.ual.com',
        URL_UI_ORIGIN: 'https://orgchart.dev.dqr.aws.ual.com',
        OAM_AUTHORIZE_URL: 'https://signon-oqa.ual.com/oauth2/rest/authorize?client_id=DQR_Org_Chart_ODEV_CID&domain=DQR_Org_Chart_ODEV&redirect_uri=https://orgchart.dev.dqr.aws.ual.com/&code_challenge_method=S256&response_type=code&scope=openid%20profile%20DQR_Org_Chart_ODEV_RS01.All&code_challenge=',
        OAM_AUTHORIZE_REDIRECT_URL: 'https://signon-oqa.ual.com/oauth2/rest/authorize?client_id=DQR_Org_Chart_ODEV_CID&domain=DQR_Org_Chart_ODEV&code_challenge_method=S256&response_type=code&scope=openid%20profile%20DQR_Org_Chart_ODEV_RS01.All&code_challenge=',
        OAM_TOKEN_URL: 'https://signon-oqa.ual.com/oauth2/rest/token',
        OAM_TOKEN_INFO_URL: 'https://signon-oqa.ual.com/oauth2/rest/token/info',
        OAM_OAUTH_IDENTITY_DOMAIN: 'DQR_Org_Chart_ODEV',
        OAM_CLIENT_ID: 'DQR_Org_Chart_ODEV_CID',
        OAM_CLIENT_NAME: 'DQR_Org_Chart_ODEV_CNAME',
        OAM_CLIENT_AUTH: 'RFFSX09yZ19DaGFydF9PREVWX0NJRDphNjQ2ZGU2MzZjYmU0ZmI0OWE5OGM2MDM4MzhmMDM3OA==',
        OAM_REDIRECT_URI: 'https://orgchart.dev.dqr.aws.ual.com/',
        OAM_REDIRECT_DEEPLINK_URI: 'https://orgchart.dev.dqr.aws.ual.com/redirect/',
        // OAM_CODE_VERIFIER: 'ecaaf9ba8d2d092e92cf0d42818dfa77861d0ce6260720d89b77da02',
        // OAM_CODE_CHALLENGE: 'jyJGW3s73cvrVW4w7tMBV_E7mZFrGWOkp9ODKcHM2Vc',
        OAM_CODE_CHALLENGE_METHOD: 'S256',
        OAM_SCOPE: 'openid profile DQR_Org_Chart_ODEV_RS01.All',
        OAM_LOGOUT: 'https://signon-oqa.ual.com/oam/server/logout',
        DD_APP_ID: '88a6f5fa-7ff2-4f3e-9672-7dcacc882155',
        DD_CLIENT_TOKEN: 'pub80a2a41427970a790327423fbca0dcbc',
        DD_SERVICE: 'orgchart-web-dev',
        DD_ENV: 'dev'
    },
    'qa': {
        ENV: 'qa',
        URL_ORG_API_BASE: 'https://orgchart-api.qa.dqr.aws.ual.com',
        URL_UI_ORIGIN: 'https://orgchart.qa.dqr.aws.ual.com',
        OAM_AUTHORIZE_URL: 'https://signon-oqa.ual.com/oauth2/rest/authorize?client_id=DQR_Org_Chart_OQA_CID&domain=DQR_Org_Chart_OQA&redirect_uri=https://orgchart.qa.dqr.aws.ual.com/&code_challenge_method=S256&response_type=code&scope=openid%20profile%20DQR_Org_Chart_OQA_RS01.ALL&code_challenge=',
        OAM_AUTHORIZE_REDIRECT_URL: 'https://signon-oqa.ual.com/oauth2/rest/authorize?client_id=DQR_Org_Chart_OQA_CID&domain=DQR_Org_Chart_OQA&code_challenge_method=S256&response_type=code&scope=openid%20profile%20DQR_Org_Chart_OQA_RS01.ALL&code_challenge=',
        OAM_TOKEN_URL: 'https://signon-oqa.ual.com/oauth2/rest/token',
        OAM_TOKEN_INFO_URL: 'https://signon-oqa.ual.com/oauth2/rest/token/info',
        OAM_OAUTH_IDENTITY_DOMAIN: 'DQR_Org_Chart_OQA',
        OAM_CLIENT_ID: 'DQR_Org_Chart_OQA_CID',
        OAM_CLIENT_NAME: 'DQR_Org_Chart_OQA_CNAME',
        OAM_CLIENT_AUTH: 'RFFSX09yZ19DaGFydF9PUUFfQ0lEOjhmM2IwNDQ5OGIzNTQ5YTBhYzBlMTFkZTA2ZGNlNjhi',
        OAM_REDIRECT_URI: 'https://orgchart.qa.dqr.aws.ual.com/',
        OAM_REDIRECT_DEEPLINK_URI: 'https://orgchart.qa.dqr.aws.ual.com/redirect/',
        // OAM_CODE_VERIFIER: 'cde20d5f8d3dd082ae9787ee9efce636dbb408278db9f4fde872fe36',
        // OAM_CODE_CHALLENGE: 'mWFPSxqd4rpje5gjINkFggrNn_40W_6xTdW3p80h1-g',
        OAM_CODE_CHALLENGE_METHOD: 'S256',
        OAM_SCOPE: 'openid profile DQR_Org_Chart_OQA_RS01.ALL',
        OAM_LOGOUT: 'https://signon-oqa.ual.com/oam/server/logout',
        DD_APP_ID: '88a6f5fa-7ff2-4f3e-9672-7dcacc882155',
        DD_CLIENT_TOKEN: 'pub80a2a41427970a790327423fbca0dcbc',
        DD_SERVICE: 'orgchart-web-qa',
        DD_ENV: 'qa'
    },
    'stg': {
        ENV: 'stg',
        URL_ORG_API_BASE: 'https://orgchart-api.stg.dqr.aws.ual.com',
        URL_UI_ORIGIN: 'https://orgchart.stg.dqr.aws.ual.com',
        OAM_AUTHORIZE_URL: 'https://signon.ual.com/oauth2/rest/authorize?client_id=DQR_ORG_CHART_STG_CID&domain=DQR_ORG_CHART_STG&redirect_uri=https://orgchart.stg.dqr.aws.ual.com/&code_challenge_method=S256&response_type=code&scope=openid%20profile%20DQR_ORG_CHART_STG_RS01.ALL&code_challenge=',
        OAM_AUTHORIZE_REDIRECT_URL: 'https://signon.ual.com/oauth2/rest/authorize?client_id=DQR_ORG_CHART_STG_CID&domain=DQR_ORG_CHART_STG&code_challenge_method=S256&response_type=code&scope=openid%20profile%20DQR_ORG_CHART_STG_RS01.ALL&code_challenge=',
        OAM_TOKEN_URL: 'https://signon.ual.com/oauth2/rest/token',
        OAM_TOKEN_INFO_URL: 'https://signon.ual.com/oauth2/rest/token/info',
        OAM_OAUTH_IDENTITY_DOMAIN: 'DQR_ORG_CHART_STG',
        OAM_CLIENT_ID: 'DQR_ORG_CHART_STG_CID',
        OAM_CLIENT_NAME: 'DQR_Org_Chart_STG_CNAME',
        OAM_CLIENT_AUTH: 'RFFSX09SR19DSEFSVF9TVEdfQ0lEOjlmMWEzYWJhOTMwNjRiMzZiMGJlZmI1YWY3NzRjYWE2',
        OAM_REDIRECT_URI: 'https://orgchart.stg.dqr.aws.ual.com/',
        OAM_REDIRECT_DEEPLINK_URI: 'https://orgchart.stg.dqr.aws.ual.com/redirect/',
        // OAM_CODE_VERIFIER: 'cde20d5f8d3dd082ae9787ee9efce636dbb408278db9f4fde872fe36',
        // OAM_CODE_CHALLENGE: 'mWFPSxqd4rpje5gjINkFggrNn_40W_6xTdW3p80h1-g',
        OAM_CODE_CHALLENGE_METHOD: 'S256',
        OAM_SCOPE: 'openid profile DQR_ORG_CHART_STG_RS01.ALL',
        OAM_LOGOUT: 'https://signon.ual.com/oam/server/logout',
        DD_APP_ID: '88a6f5fa-7ff2-4f3e-9672-7dcacc882155',
        DD_CLIENT_TOKEN: 'pub80a2a41427970a790327423fbca0dcbc',
        DD_SERVICE: 'orgchart-web-stg',
        DD_ENV: 'stg'
    },
    'prod': {
        ENV: 'prod',
        URL_ORG_API_BASE: 'https://orgchart-api.ual.com',
        URL_UI_ORIGIN: 'https://orgchart.ual.com',
        OAM_AUTHORIZE_URL: 'https://signon.ual.com/oauth2/rest/authorize?client_id=DQR_ORG_CHART_CID&domain=DQR_ORG_CHART&redirect_uri=https://orgchart.ual.com/&code_challenge_method=S256&response_type=code&scope=openid%20profile%20DQR_ORG_CHART_RS01.ALL&code_challenge=',
        OAM_AUTHORIZE_REDIRECT_URL: 'https://signon.ual.com/oauth2/rest/authorize?client_id=DQR_ORG_CHART_CID&domain=DQR_ORG_CHART&code_challenge_method=S256&response_type=code&scope=openid%20profile%20DQR_ORG_CHART_RS01.ALL&code_challenge=',
        OAM_TOKEN_URL: 'https://signon.ual.com/oauth2/rest/token',
        OAM_TOKEN_INFO_URL: 'https://signon.ual.com/oauth2/rest/token/info',
        OAM_OAUTH_IDENTITY_DOMAIN: 'DQR_ORG_CHART',
        OAM_CLIENT_ID: 'DQR_ORG_CHART_CID',
        OAM_CLIENT_NAME: 'DQR_Org_Chart_CNAME',
        OAM_CLIENT_AUTH: 'RFFSX09SR19DSEFSVF9DSUQ6ZTlhZmJiM2NiY2MzNGRiYjg1YjlmZWU1ZTUxMmUzZGY=',
        OAM_REDIRECT_URI: 'https://orgchart.ual.com/',
        OAM_REDIRECT_DEEPLINK_URI: 'https://orgchart.ual.com/redirect/',
        // OAM_CODE_VERIFIER: 'cde20d5f8d3dd082ae9787ee9efce636dbb408278db9f4fde872fe36',
        // OAM_CODE_CHALLENGE: 'mWFPSxqd4rpje5gjINkFggrNn_40W_6xTdW3p80h1-g',
        OAM_CODE_CHALLENGE_METHOD: 'S256',
        OAM_SCOPE: 'openid profile DQR_ORG_CHART_RS01.ALL',
        OAM_LOGOUT: 'https://signon.ual.com/oam/server/logout',
        DD_APP_ID: '88a6f5fa-7ff2-4f3e-9672-7dcacc882155',
        DD_CLIENT_TOKEN: 'pub80a2a41427970a790327423fbca0dcbc',
        DD_SERVICE: 'orgchart-web',
        DD_ENV: 'prd'
    }
}
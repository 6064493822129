import { ORG_CONFIG } from "./config-utils";

export const getEnv = () => {
    if (window.runningEnv && window.runningEnv !== "")
        return window.runningEnv;
    const isLocal = window.location.hostname.match(/localhost/);
    
    if (isLocal) {
        window.runningEnv = `local`;
        return "local";
    }
    else {
        const env = window.location.hostname.match(/dev|qa|stg/);
        if (env) {
            window.runningEnv = `${env[0]}`;
            return `${env[0]}`;
        }
    
        return "prod";
    }
};

export const getConfig = (configName) => {    
    return ORG_CONFIG[getEnv()][configName];
};
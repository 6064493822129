import React, { Component } from "react";
import { getConfig } from '../utils/env-utils';
import randomstring from "randomstring";
import CryptoJS from "crypto-js";
import { RefreshToken } from '../components/RefreshToken';

if (sessionStorage.getItem("codeVerifier") == null && sessionStorage.getItem("codeChallenge") == null){
    const codeVerifier = randomstring.generate(128);
    sessionStorage.setItem("codeVerifier", codeVerifier);
    const codeChallenge = CryptoJS.enc.Base64url.stringify(CryptoJS.SHA256(codeVerifier));
    sessionStorage.setItem("codeChallenge", codeChallenge);
}

var someText = "";
var OAM_CODE = "";
const BASE_API_URL = `${getConfig('URL_ORG_API_BASE')}`;
const URL_UI_ORIGIN = `${getConfig('URL_UI_ORIGIN')}`;
const OAM_BASE_AUTHORIZE_URL = `${getConfig('OAM_AUTHORIZE_URL')}`;
const OAM_TOKEN_URL = `${getConfig('OAM_TOKEN_URL')}`;
const OAM_TOKEN_INFO_URL = `${getConfig('OAM_TOKEN_INFO_URL')}`;
const OAM_OAUTH_IDENTITY_DOMAIN = `${getConfig('OAM_OAUTH_IDENTITY_DOMAIN')}`;
const OAM_CLIENT_ID = `${getConfig('OAM_CLIENT_ID')}`;
const OAM_CLIENT_NAME = `${getConfig('OAM_CLIENT_NAME')}`;
const OAM_CLIENT_AUTH = `${getConfig('OAM_CLIENT_AUTH')}`;
const OAM_REDIRECT_URI = `${getConfig('OAM_REDIRECT_URI')}`;
// const OAM_CODE_VERIFIER = `${getConfig('OAM_CODE_VERIFIER')}`;
// const OAM_CODE_CHALLENGE = `${getConfig('OAM_CODE_CHALLENGE')}`;
const OAM_CODE_VERIFIER = sessionStorage.getItem("codeVerifier");
const OAM_CODE_CHALLENGE = sessionStorage.getItem("codeChallenge");
const OAM_CODE_CHALLENGE_METHOD = `${getConfig('OAM_CODE_CHALLENGE_METHOD')}`;
const OAM_SCOPE = `${getConfig('OAM_SCOPE')}`;
const ENV = `${getConfig('ENV')}`;
const OAM_AUTHORIZE_URL = OAM_BASE_AUTHORIZE_URL + OAM_CODE_CHALLENGE;

export async function AuthenticateRequest() {
    //local
    if (ENV === 'local'){
        sessionStorage.setItem("access_token", "access_token");
        sessionStorage.setItem("refresh_token", "refresh_token");
        sessionStorage.setItem("APP_LOADED", 'Y');
        return 0;
    }

    //console.log("authenticate request initiated.");
    //console.log("referred by - " + document.referrer);
    var refresh_token = sessionStorage.getItem("refresh_token");
    var access_token = sessionStorage.getItem("access_token");
    var app_loaded = sessionStorage.getItem("APP_LOADED");
    if (app_loaded === null){
        app_loaded = 'N';
    }
    
    if (app_loaded === 'Y'){
        //check token info for access token validity
        if (access_token !== null && access_token !== ""){
            //has access token. let's verify and make sure it's good
            sessionStorage.setItem("timeout_counter", "0");
            var myHeaders = new Headers();
            myHeaders.append("x-oauth-identity-domain-name", OAM_OAUTH_IDENTITY_DOMAIN);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            
            var urlencoded = new URLSearchParams();
            urlencoded.append("access_token", access_token);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded
            };
            var needsRefresh = "NO";
            await fetch(OAM_TOKEN_INFO_URL, requestOptions)
                .then(response => {
                    if (response.status != 200){
                        //error validating access token. Let's try to refresh token and see
                        RefreshToken();
                        needsRefresh = "OK";
                        return needsRefresh;
                    }
                    return response.json();
                })
                .then(result => {
                    var stringy = JSON.stringify(result);  
                    var jsonObj = JSON.parse(stringy);
                    var rem_exp = jsonObj["rem_exp"];
                    sessionStorage.setItem("rem_exp", rem_exp);
                    var exp_time = new Date();
                    exp_time.setSeconds(exp_time.getSeconds() + parseInt(rem_exp));
                    sessionStorage.setItem("timeout_time", exp_time);
                    sessionStorage.setItem("APP_LOADED", 'Y');
                    return "OK";
                })
                .catch(error => {
                    //error validating refresh token. Login again
                    if (needsRefresh === "OK"){
                        window.location.reload();
                    } else {
                        window.location.href = OAM_AUTHORIZE_URL;   
                    } 
                });
        }
        else
        {
            console.log("in AUTHENTICATE REQUEST - no access token");
            //no refresh token. so now we redirect back to login page
            window.location.href = OAM_AUTHORIZE_URL;
        }
        
        // if (refresh_token !== null && refresh_token !== ""){
        //     //has refresh token. call refresh api and get updated access token
        //     var myHeaders = new Headers();
        //     myHeaders.append("Authorization", "Basic " + OAM_CLIENT_AUTH);
        //     myHeaders.append("x-oauth-identity-domain-name", OAM_OAUTH_IDENTITY_DOMAIN);
        //     myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        //     //myHeaders.append("Cookie", "oam_int_devqa=rd4o00000000000000000000ffff0aa41d45o4443");

        //     var urlencoded = new URLSearchParams();
        //     urlencoded.append("refresh_token", refresh_token);
        //     urlencoded.append("grant_type", "REFRESH_TOKEN");
        //     urlencoded.append("redirect_uri", OAM_REDIRECT_URI);
        //     urlencoded.append("response_type", "token");
        //     urlencoded.append("code_verifier", OAM_CODE_VERIFIER);

        //     var requestOptions = {
        //         method: 'POST',
        //         headers: myHeaders,
        //         body: urlencoded,
        //         redirect: 'follow'
        //     };

        //     var retVal = await fetch(OAM_TOKEN_URL, requestOptions)
        //         .then(response => response.json())
        //         .then(result => {
        //             var stringy = JSON.stringify(result);          
        //             // console.log("next 2 responses are from refresh token in authenticate request");
        //             // console.log(stringy);
        //             var jsonObj = JSON.parse(stringy);
        //             // console.log(jsonObj);

        //             access_token = jsonObj["access_token"];
        //             refresh_token = jsonObj["refresh_token"];

        //             sessionStorage.setItem("access_token", access_token);
        //             sessionStorage.setItem("refresh_token", refresh_token);
        //             sessionStorage.setItem("APP_LOADED", 'Y');
        //             return "OK";
        //         })
        //         .catch(error => {
        //             console.log('refresh token error', error);
        //             //error validating refresh token. Login again
        //             window.location.href = OAM_AUTHORIZE_URL;    
        //         });

        // }
        // else
        // {
        //     console.log("in validatetoken - no refresh token");
        //     //no refresh token. so now we redirect back to login page
        //     window.location.href = OAM_AUTHORIZE_URL;
        // }
    }
    else
    {
        console.log("APP NOT LOADED AUTHENTICATE REQUEST. DO LOGIC HERE OR WHAT?");
        if ((access_token == null || access_token == "") && (refresh_token == null || refresh_token == "")) {
            //nothing in session storage. redirect to login page
            window.location.href = OAM_AUTHORIZE_URL;
        }

    }
}
import './App.css';
import './js/fontawesome/css/all.css';
import './css/site.css';
import './css/landing.css';
import './css/main.master.css';
import './css/survey.css';
import './css/admin-toolbar.css';
import './css/content-box.css';
import './css/united-colors.css';
import './css/switches.css';
import './css/administration.css';
import './css/jquery-ui.css';
import './js/slick/slick.css';
import './js/slick/slick-theme.css';
import React, {useState} from 'react';

import { getConfig } from './utils/env-utils';

import { BrowserRouter, Route, Routes} from 'react-router-dom';
import OrgChartMainEmployee from './pages/OrgChartMainEmployee';
import SearchResults from './pages/SearchResults';
import Login from './pages/Login';
import Home from './pages/Home';
import Header from './components/Header';
import Logout from './pages/Logout';
import Redirect from './pages/Redirect';
import { datadogRum } from '@datadog/browser-rum';

const URL_UI_ORIGIN = `${getConfig('URL_UI_ORIGIN')}`;
const DD_APP_ID = `${getConfig('DD_APP_ID')}`;
const DD_CLIENT_TOKEN = `${getConfig('DD_CLIENT_TOKEN')}`;
const DD_SERVICE = `${getConfig('DD_SERVICE')}`;
const DD_ENV = `${getConfig('DD_ENV')}`;

datadogRum.init({
    applicationId: DD_APP_ID,
    clientToken: DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: DD_SERVICE,
    env: DD_ENV,
    //version: 'v1.0', 
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins: [
      { match: URL_UI_ORIGIN, propagatorTypes: ["datadog", "tracecontext"]}
    ]
});
datadogRum.startSessionReplayRecording();
  
  
function App() {
  //this.state.searchText = enteredText;
  return (
    <BrowserRouter>
      <div className="App">
        <Header/>     
      </div>
     <Routes>
        <Route exact path="/*" element={<Login />} />
        <Route exact path="/Home/" element={<Home />} />
        <Route exact path="/OrgChart/*" element={<OrgChartMainEmployee/>} />
        <Route exact path="/SearchResults/*" element={<SearchResults/>} />
        <Route exact path="/Logout/" element={<Logout/>} />
        <Route exact path="/Redirect/*" element={<Redirect/>} />
      </Routes>
    </BrowserRouter>
  );
}
  
export default App;
//all our css files
import '../js/fontawesome/css/all.css';
import '../css/site.css';
import '../css/landing.css';
import '../css/header.css';
import '../css/main.master.css';
import '../css/survey.css';
import '../css/admin-toolbar.css';
import '../css/content-box.css';
import '../css/united-colors.css';
import '../css/switches.css';
import '../css/administration.css';
import '../css/jquery-ui.css';
import '../js/slick/slick.css';
import '../js/slick/slick-theme.css';
import "bootstrap/dist/css/bootstrap.min.css";

//all our javascript fiiles
//import './js/jquery/dist/jquery.min.js';
import bootstrap from 'bootstrap';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown'
import $ from 'jquery';
import { findDOMNode } from 'react-dom';
//import './js/bootstrap/dist/js/bootstrap.bundle.min.js';
import logo from '../images/United logo.svg';
import email from "../images/icons/Email.svg";
import location from "../images/icons/Location.svg";
import traveler from "../images/icons/Traveler.svg";
import search from "../images/icons/Search.svg";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
//import './js/jquery-ui.min.js';
import { TimeoutRequest } from '../components/TimeoutRequest';

import moment from 'moment';
import popper from 'react-popper';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';

// import './js/popper.min.js';
// import './js/moment.min.js';
// import slick from 'slick-carousel';
// import './js/site.js';

import { getConfig } from '../utils/env-utils';

const BASE_API_URL = `${getConfig('URL_ORG_API_BASE')}`;
const URL_UI_ORIGIN = `${getConfig('URL_UI_ORIGIN')}`;

function Header() {
  const [btnState, setEnable] = useState(true);
  const [dropdownData, setDDLListEntries] = useState(() => LoadFromSession('ORG_SEARCH_DDL'));
  const [currentEmployeeData, setCurrentEmployeeInfo] = useState(() => LoadFromSession('CURRENTEMPL'));
  var access_token = sessionStorage.getItem("access_token");

  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log("two seconds later...");
  //     $(".empl-toolbar-btn").on("click", function(){
  //       console.log("HERE");
  //       $(this).parent().toggleClass("show");
  //       $(".empl-toolbar-dropdown").toggleClass("show");
  //       $(this).find(".rotate").toggleClass("down"); 
  //     });

  //     $(".btn-search-bar").on("click", function(){
  //       $("#searchBar").toggleClass("hidden-sm");
  //     });

  //   }, 2000);
  // });


  function LoadFromSession(key) {
    const item = window.sessionStorage.getItem(key);
    return item != null ? JSON.parse(item) : [];
  };

  useEffect(() => {
    window.sessionStorage.setItem('ORG_SEARCH_DDL', JSON.stringify(dropdownData));
  }, [dropdownData]);
  
  useEffect(() => {
    window.sessionStorage.setItem('CURRENTEMPL', JSON.stringify(currentEmployeeData));
  }, [currentEmployeeData]);
  
  if (access_token != null) {
    if (currentEmployeeData != null && currentEmployeeData.length == 0)
    {
      fetch(
        BASE_API_URL + `/api/v1/OrgChartAPI/GetCurrentEmployeeInfo`,
        {
          method: "GET",
          headers: {
            'authorizationtoken': access_token,
            'Origin': URL_UI_ORIGIN
          }
        }
      )
      .then((res) => {
        if (res.status != 200){
          //window.location.href = "/Logout/Timeout";
          TimeoutRequest();
        }
        return res.json();
      })
        .then(result => {
            sessionStorage.setItem("CURRENTEMPL", JSON.stringify([result]));
            setCurrentEmployeeInfo([result]);
        })
        .catch(error => 
          console.log('Error on the fetch ' + error)
        );
    }


      if (dropdownData != null && dropdownData.length == 0)
      {
        fetch(
          BASE_API_URL + `/api/v1/OrgChartAPI/GetSearchDropDownList`,
          {
            method: "GET",
            headers: {
              'authorizationtoken': access_token,
              'Origin': URL_UI_ORIGIN
            }
          }
        )
        .then((res) => {
          if (res.status != 200){
            //window.location.href = "/Logout/Timeout";
            TimeoutRequest();
          }
          return res.json();
        })
          .then(result => {         
              sessionStorage.setItem("ORG_SEARCH_DDL", JSON.stringify([result]));
              setDDLListEntries([result]);
          })
          .catch(error => console.log('Error on the fetch ' + error));
      }
  }

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

const SetFixedHeaderPadding = () => {
  var height = $('.dont-move-header').height();
  $('.main-wrap').css({
    'padding-top': height
  });
};

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  if (finalpath === 'SearchResults') {
    SetFixedHeaderPadding();
  }
});




  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  var checkboxSupplier = true;
  //var SearchValue = '';
  var SearchValueFName = '';
  var SearchValueLName = '';
  //var ASearchName = '';
  var ASearchFName = '';
  var ASearchLName = '';
  var ASearchTitle = '';
  var ASearchDivision = '';
  var FilterBtnDivision = '';
  var ASearchLocation = '';
  var HasFilter = 'N';

  if (sessionStorage.getItem("supplierCheck") === null) {
    checkboxSupplier = true;
    sessionStorage.setItem("supplierCheck", "Y")
  } else {
    if (sessionStorage.getItem("supplierCheck") === "Y") {
      checkboxSupplier = true;
    } else {
      checkboxSupplier = false;
    }
  }

  //This applies only if on the SearchResults Page
  var path = window.location.pathname;
  var patharr = path.split("/")
  var finalpath = patharr[1];
  var displayClass = "";
  
  if (finalpath === "" || finalpath === "Logout"){
    //login page
    displayClass = "hide";
  }

  if (finalpath === 'SearchResults') {
    setTimeout(
      function() {
        SetFixedHeaderPadding();
      }, 1000);


    //if (sessionStorage.getItem("searchText") !== null) {
    //  SearchValue = sessionStorage.getItem("searchText");
    //}
    //The Logic for searchText will go away TAL
    //if (sessionStorage.getItem("searchText") !== null) {
    //  ASearchName = sessionStorage.getItem("searchText");
    //}
    HasFilter = 'N';
    if (sessionStorage.getItem("searchTextFName") !== null) {
      SearchValueFName = sessionStorage.getItem("searchTextFName");
      ASearchFName = sessionStorage.getItem("searchTextFName");
      HasFilter = 'Y';
    }

    if (sessionStorage.getItem("searchTextLName") !== null) {
      SearchValueLName = sessionStorage.getItem("searchTextLName");
      ASearchLName = sessionStorage.getItem("searchTextLName");
      HasFilter = 'Y';
    }

    if (sessionStorage.getItem("Title") !== null) {
      ASearchTitle = sessionStorage.getItem("Title");
      HasFilter = 'Y';
    }

    if (sessionStorage.getItem("Division") !== null) {
      ASearchDivision = sessionStorage.getItem("Division");
      FilterBtnDivision = sessionStorage.getItem("FilterBtnDivision");
      HasFilter = 'Y';
    }

    if (sessionStorage.getItem("Location") !== null) {
      ASearchLocation = sessionStorage.getItem("Location");
      HasFilter = 'Y';
    }
  }



  const handleClear = () => {
    document.getElementById("txt_FirstName").value = "";
    document.getElementById("txt_LastName").value = "";
    document.getElementById("txt_Title").value = "";
    document.getElementById("sel_Division").value = "";
    document.getElementById("sel_Location").value = "";
    setEnable(true);

  };

  const handleSearch = () => {
    //Thhe next line goes away TAL
    //sessionStorage.setItem("searchText", document.getElementById("txt_Name").value);
    sessionStorage.setItem("searchTextFName", document.getElementById("txt_FirstName").value);
    sessionStorage.setItem("searchTextLName", document.getElementById("txt_LastName").value);
    sessionStorage.setItem("Title", document.getElementById("txt_Title").value);
    sessionStorage.setItem("Division", document.getElementById("sel_Division")[document.getElementById("sel_Division").selectedIndex].value);
    sessionStorage.setItem("FilterBtnDivision", document.getElementById("sel_Division")[document.getElementById("sel_Division").selectedIndex].text);
    sessionStorage.setItem("Location", document.getElementById("sel_Location")[document.getElementById("sel_Location").selectedIndex].value);

    setShow(false);
    var path = window.location.pathname;
    var patharr = path.split("/")
    var finalpath = patharr[1];
    if (finalpath === 'SearchResults') {
      window.location.reload();
    }
    else {
      navigate("/SearchResults/");
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      var path = window.location.pathname;
      var patharr = path.split("/")
      var finalpath = patharr[1];

      sessionStorage.setItem("Title", "");
      sessionStorage.setItem("Division", "");
      sessionStorage.setItem("FilterBtnDivision", "");
      sessionStorage.setItem("Location", "");
      console.log(event.target.id);
      if (finalpath === 'SearchResults') {
        //sessionStorage.setItem("searchText", event.target.value);
        sessionStorage.setItem("searchTextFName", document.getElementById("txt_searchFName").value);
        sessionStorage.setItem("searchTextLName", document.getElementById("txt_searchLName").value);
        window.location.reload();
      }
      else {
        //sessionStorage.setItem("searchText", event.target.value);
        sessionStorage.setItem("searchTextFName", document.getElementById("txt_searchFName").value);
        sessionStorage.setItem("searchTextLName", document.getElementById("txt_searchLName").value);
        navigate("/SearchResults/");
      }
    };
  };

  const handleCheckBox = event => {
    var path = window.location.pathname;
    var patharr = path.split("/")
    var finalpath = patharr[1];
    var supplierCheck = 'Y';

    if ($("#chk_supplier").is(':checked') === true) {
      supplierCheck = 'Y';
    } else {
      supplierCheck = 'N';
    }
    sessionStorage.setItem("supplierCheck", supplierCheck);

    if (finalpath !== '' && finalpath !== 'Home') {
      //navigate(window.location.pathname);
      window.location.reload();
    }
  }

  const handleRemoveFilter = event => {

    var path = window.location.pathname;
    var patharr = path.split("/")
    var finalpath = patharr[1];

    switch (event.currentTarget.getAttribute("value")) {
      case 'First Name':
        sessionStorage.setItem("searchTextFName", "");
        //ASearchName = "";
        ASearchFName = "";
        break;
        case 'Last Name':
          sessionStorage.setItem("searchTextLName", "");
          //ASearchName = "";
          ASearchLName = "";
          break;        
      case 'Title':
        sessionStorage.setItem("Title", "");
        ASearchTitle = "";
        break;
      case 'Division':
        sessionStorage.setItem("Division", "");
        sessionStorage.setItem("FilterBtnDivision", "");
        ASearchDivision = "";
        FilterBtnDivision = "";
        break;
      case 'Location':
        sessionStorage.setItem("Location", "");
        ASearchLocation = "";
        break;
      case 'All':
        sessionStorage.setItem("searchTextFName", "");
        ASearchFName = "";
        sessionStorage.setItem("searchTextLName", "");
        ASearchLName = "";
        sessionStorage.setItem("Title", "");
        ASearchTitle = "";
        sessionStorage.setItem("Division", "");
        sessionStorage.setItem("FilterBtnDivision", "");
        ASearchDivision = "";
        FilterBtnDivision = "";
        sessionStorage.setItem("Location", "");
        ASearchLocation = "";
        break;
    }

    if (ASearchFName === "" && ASearchLName === "" && ASearchTitle === "" && ASearchDivision === "" && ASearchLocation === "") {
      navigate("/Home/");
    }

    if (finalpath === 'SearchResults') {
      window.location.reload();
    }
    else {
      navigate("/SearchResults/");
    }

  }

  const handleChange = event => {
    if (document.getElementById("txt_FirstName").value === "" && document.getElementById("txt_LastName").value === "" && document.getElementById("txt_Title").value === "" && document.getElementById("sel_Division").value === "" &&
       document.getElementById("sel_Location").value === "") {
      setEnable(true);
    }
    else {
      setEnable(false);
    }
    defaultChevron();

  }

  const rotateChevronDivision = event => {
    $(".division-arrow").find(".rotate").addClass("down"); 
  }

  const rotateChevronLocation = event => {
    $(".location-arrow").find(".rotate").addClass("down"); 
  }

  const defaultChevron = event => {
    $(".location-arrow").find(".rotate").removeClass("down"); 
    $(".division-arrow").find(".rotate").removeClass("down"); 
  }

  const searchButtonClick = event => {
    $("#searchBar").toggleClass("hidden-sm");
  }

  const currentEmployeeProfileClick = event => {
    $(".empl-toolbar-btn").parent().toggleClass("show");
    $(".empl-toolbar-dropdown").toggleClass("show");
    $(".empl-toolbar-btn").find(".rotate").toggleClass("down"); 
    $(".empl-button-master").toggleClass("empl-button-active-color");
  }

  return (
    <div className={displayClass}>
      <header className="bg-white dont-move-header">
        <nav className="navbar rtw-navbar">
          <div className="navbar-nav">
            <div className="nav-item">
              <a href="/Home" className="header-link"><img src={logo} alt="united logo" className="united-logo" /></a>
            </div>
          </div>
          <div className="navbar-nav text-center hidden-sm">
            <div className="nav-item app-title-center">
              <a href="/Home" className="header-text-link">Org Chart</a>
            </div>
          </div>

          <div className="navbar-nav text-right text-white empl-button-master">

          {currentEmployeeData.length > 0 && (
            <div className="dropdown"> 
              <button className="empl-toolbar-btn" type="button" id="dropdownMenuButton" onClick={currentEmployeeProfileClick}>  
                <div className="empl-toolbar-container">   
                  {currentEmployeeData[0].OnCurrentOrg === "Y" && (
                    <div className="empl-toolbar-name">    
                      <span className="hidden-sm"><strong>{currentEmployeeData[0].Name}</strong><br />{currentEmployeeData[0].Title}</span> { }  
                    </div>
                  )}

                  {currentEmployeeData[0].OnCurrentOrg === "N" && (
                    <div className="empl-toolbar-name-inactive">    
                      <span className="hidden-sm"><strong>{currentEmployeeData[0].Name}</strong></span> { }  
                    </div>
                  )}

                  <div className="empl-img-size-profile">    
                    <div className="empl-img-profile">
                      {currentEmployeeData[0].PhotoString.length > 0 && (
                        <div className="image-cropper-tbl">
                          <img className="profile-pic-tbl" src={currentEmployeeData[0].PhotoString} />
                        </div>
                      )}

                      {currentEmployeeData[0].PhotoString.length == 0 && (
                        <div className="initial-cropper-tbl">
                          {currentEmployeeData[0].Initials}
                        </div>
                      )}
                        
                    </div>   
                  </div>  
                  <div className="empl-toolbar-arrow">    
                    <i className="fa fa-chevron-down rotate"></i> 
                  </div>
                </div> 
              </button> 
              <div className="empl-toolbar-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton">
              {currentEmployeeData[0].OnCurrentOrg === "Y" && (
                <div className="empl-toolbar-container">   
                  <div className="empl-img-size-medium">    
                    <div className="empl-img-medium">
                      
                      {currentEmployeeData[0].PhotoString.length > 0 && (
                        <div className="image-cropper-small">
                          <img className="width-100" src={currentEmployeeData[0].PhotoString} />
                        </div>
                      )}

                      {currentEmployeeData[0].PhotoString.length == 0 && (
                        <div className="initial-cropper-small">
                          {currentEmployeeData[0].Initials}
                        </div>
                      )}

                    </div>   
                  </div>  
                  <div className="empl-toolbar-name-big">    
                    <strong>{currentEmployeeData[0].Name}</strong>
                    <br />
                    {currentEmployeeData[0].Title}
                  </div>  
                </div> 
              )}
                
                {currentEmployeeData[0].OnCurrentOrg === "Y" && (
                  <div>
                    <hr className='sidenav-line' />

                    <div className="org-links-profile">
                      <div>
                        <img src={email} alt="" className="org-icon-profile" />{"Email "}
                        <a className="org-links-value-profile" href={"mailto:" + currentEmployeeData[0].Email} target="_blank" rel="noopener noreferrer">{currentEmployeeData[0].Email}</a>
                      </div>
                    </div>

                      <div className="org-links-profile">
                        <div>
                          <img src={location} alt="" className="org-icon-profile" />{"Location "}
                            <span className='org-links-value-profile'>{currentEmployeeData[0].Location}</span>
                        </div>
                      </div>

                      <div className="org-links-profile">
                        <div>
                          <img src={traveler} alt="" className="org-icon-profile" />{"Reports to "}
                          <a className="org-links-value-profile" href={"/OrgChart/" + currentEmployeeData[0].ManagerID}>{currentEmployeeData[0].ManagerName}</a>
                        </div>
                      </div>
                      {currentEmployeeData[0].SupplierIndicator === "N" && (
                        <a href="https://peoplesoft.ual.com/psc/prd/EMPLOYEE/HRMS/c/UAH_ESS_CUSTOM.UAH_EE_CONTACTS_FL.GBL?Page=UAH_EE_CONTACTS_FL&Action=U" target="_blank" className="org-button-link-main">Edit Profile Information</a>
                      )}
                    </div>
                )}
                  
                <a href="/Logout" className="org-button-link-signout">Sign out</a>

              </div>
            </div> 
          )}
          </div>

        </nav>

        <div className={HasFilter === "N" ? "search-bar-row" : "search-bar-row-smaller"}>
          <div className="div-name-search">
            <span>
              {/* <i className="fa fa-search first-name-search-icon" onClick={handleKeyDown}></i> */}
              <img src={search} alt="search" className='first-name-search-icon' onClick={handleKeyDown}></img>
              <input type="text" className="jAuto first-name-search-txt" placeholder="First Name" id="txt_searchFName" name="txt_searchFName" onKeyDown={handleKeyDown} defaultValue={SearchValueFName} maxLength="20" />
            </span>
            <span>
              {/* <i className="fa fa-search last-name-search-icon" onClick={handleKeyDown}></i> */}
              <img src={search} alt="search" className='last-name-search-icon' onClick={handleKeyDown}></img>
              <input type="text" className="jAuto last-name-search-txt" placeholder="Last Name" id="txt_searchLName" name="txt_searchLName" onKeyDown={handleKeyDown} defaultValue={SearchValueLName} maxLength="20"/>
            </span>
          </div>

          <div className="div-advanced-search">
            <span className="advanced-search-span" onClick={handleShow}>
              <span className="advanced-search-link pointer" >
                Advanced Search
              </span>
              <i className="fa fa-chevron-right advanced-search-icon pointer" aria-hidden="true"></i>
            </span>

            <span>
              <label className="chk-container-header">
                <input type="checkbox" onChange={handleCheckBox} id="chk_supplier" name="chk_supplier" defaultChecked={checkboxSupplier} />
                <span className="chk-checkmark"></span>
                Hide suppliers and associates
              </label>
            </span>
          </div>
        </div>

          {HasFilter === "Y" && (
            <div className="search-bar-row-pills">
              <button type="button" className="search-bar-remove-all-pill" value="All" onClick={handleRemoveFilter}>Remove all filters</button>
              {ASearchFName !== "" &&
                <button type="button" className="search-bar-pill" value="First Name" onClick={handleRemoveFilter}>{ASearchFName}&nbsp;&nbsp;<span aria-hidden="true" className="fa-solid fa-circle-xmark"></span></button>
              }
              {ASearchLName !== "" && 
                <button type="button" className="search-bar-pill" value="Last Name" onClick={handleRemoveFilter}>{ASearchLName}&nbsp;&nbsp;<span aria-hidden="true" className="fa-solid fa-circle-xmark"></span></button>
              }              
              {ASearchTitle !== "" &&
                <button type="button" className="search-bar-pill" value="Title" onClick={handleRemoveFilter}>{ASearchTitle}&nbsp;&nbsp;<span aria-hidden="true" className="fa-solid fa-circle-xmark" /></button>
              }
              {ASearchDivision !== "" &&
                <button type="button" className="search-bar-pill" value="Division" onClick={handleRemoveFilter}>{FilterBtnDivision}&nbsp;&nbsp;<span aria-hidden="true" className="fa-solid fa-circle-xmark" /></button>
              }
              {ASearchLocation !== "" &&
                <button type="button" className="search-bar-pill" value="Location" onClick={handleRemoveFilter}>{ASearchLocation}&nbsp;&nbsp;<span aria-hidden="true" className="fa-solid fa-circle-xmark" /></button>
              }
            </div>
          )}
          
        <Modal 
          show={show} 
          onHide={handleClose} 
          className='my-modal modal-mobile-override' 
          backdrop="static" 
          keyboard={false}
          >
          <Modal.Header closeButton closeVariant="white" className="modal-header-background">
            <Modal.Title>Advanced search</Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <Container>
              <Row>
                <Col md={6}>
                  <h6 className="grey-header-lbl">First Name</h6>
                  <div className="text-bold">
                    <input type="text" id="txt_FirstName" name="txt_FirstName" label="First Name" className='form form-control' onChange={handleChange} defaultValue={ASearchFName} maxLength="20"></input>
                  </div>
                </Col>
                <Col md={6}>
                  <h6 className="grey-header-lbl">Last Name</h6>
                  <div className="text-bold">
                    <input type="text" id="txt_LastName" name="txt_LastName" label="Last Name" className='form form-control' onChange={handleChange} defaultValue={ASearchLName} maxLength="20"></input>
                  </div>
                </Col>                
                <Col md={12}>
                  <h6 className="grey-header-lbl">Job title</h6>
                  <div className="text-bold">
                    <input type="text" id="txt_Title" className='form form-control' onChange={handleChange} defaultValue={ASearchTitle} maxLength="40"></input>
                  </div>
                </Col>
              </Row>
              <Row>
                {dropdownData.length > 0 &&
                  dropdownData.map(mydata =>
                    <Col key="myDivisions" md={12}>
                      <h6 className="grey-header-lbl">Division</h6>
                      <div className="text-bold">
                        <div className="empl-toolbar-arrow division-arrow dropdown-arrow">    
                          <i className="fa fa-chevron-down rotate"></i> 
                        </div>
                        <select id='sel_Division' className='form form-control' onFocus={rotateChevronDivision} onBlur={defaultChevron} onChange={handleChange} defaultValue={ASearchDivision}>
                          <option selected></option>
                          {mydata.Divisions.length > 0 &&
                            mydata.Divisions.map(div =>
                              <option key={div.ID} value={div.ID}>{div.Description}</option>
                            )}
                        </select>
                      </div>
                    </Col>
                  )}
              </Row>
              <Row>
                {dropdownData.length > 0 &&
                  dropdownData.map(mydata =>
                    <Col key="myLocations" md={12}>
                      <h6 className="grey-header-lbl" for="sel_Location">Location</h6>
                      <div className="text-bold">
                      <div className="empl-toolbar-arrow location-arrow dropdown-arrow">    
                          <i className="fa fa-chevron-down rotate"></i> 
                        </div>
                        <select id='sel_Location' className='form form-control' onFocus={rotateChevronLocation} onBlur={defaultChevron} onChange={handleChange} defaultValue={ASearchLocation}>
                          <option selected></option>
                          {mydata.Locations.length > 0 &&
                            mydata.Locations.map(loc =>
                              <option key={loc.ID} value={loc.ID}>{loc.Description}</option>
                            )}
                        </select>
                      </div>
                    </Col>
                  )}
                <Col md={6}>
                </Col>
              </Row>
            </Container>

          </Modal.Body>
          <hr className="modal-footer-line" />
          <Modal.Footer className='modal-footer-override'>
            <Button id="btnClear" name="btnClear" variant="custom" className="modal-button-disabled modal-submit-button-width margin-top-small-screen " onClick={handleClear}>
              Clear
            </Button>
            <Button id="btnSearch" name="btnSearch" variant="custom" className="modal-button-enabled modal-submit-button-width margin-top-small-screen pointer" disabled={btnState} onClick={handleSearch}>
              Search
            </Button>
          </Modal.Footer>
        </Modal>
      </header>
    </div>  
  );
}

export default Header;

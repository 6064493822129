import React from 'react';
import RightArrow from '../images/icons/Chevron right.svg';
import {OverlayTrigger, Popover } from 'react-bootstrap';
import $ from 'jquery';
//import ActiveUserId from "../components/emplid.js";
import { getConfig } from '../utils/env-utils';
import { AuthenticateRequest } from '../components/AuthenticateRequest';
import { TimeoutRequest } from '../components/TimeoutRequest';

const BASE_API_URL = `${getConfig('URL_ORG_API_BASE')}`;
const URL_UI_ORIGIN = `${getConfig('URL_UI_ORIGIN')}`;



const popoverTop = (value) => (
  <Popover id="popover-basic">
      <div className="zoomed-photo">
          <img src={value} alt="" />
      </div>
  </Popover>
);     

class SearchResults extends React.Component {
    constructor(props) {
      super(props);
      this.state={employees: []}
    }

    componentDidMount() {
      var access_token = sessionStorage.getItem("access_token");
      //replace emplid with current employee id  
      //console.log("componentDidMount");
      fetch(BASE_API_URL + "/api/v1/OrgChartAPI/GetSearchResults", {
        method : "POST",
        headers: {
          'authorizationtoken': access_token,
          'Origin': URL_UI_ORIGIN,
          'Content-Type': 'application/json'
      },
        body: JSON.stringify({
          "firstname": sessionStorage.getItem('searchTextFName'),
          "lastname": sessionStorage.getItem('searchTextLName'),
          "title": sessionStorage.getItem('Title'),
          "division": sessionStorage.getItem('Division'),
          "hideSupplier": sessionStorage.getItem('supplierCheck'),
          "location": sessionStorage.getItem('Location')
        })
    }).then((res) => {
      if (res.status != 200){
        //window.location.href = "/Logout/Timeout";
        TimeoutRequest();
      }
      return res.json();
    })
      .then(
        (result) => {
          //console.log(result);
          var newData = this.state.employees.concat([result]);
          this.setState({employees: newData});
          //If only one employee, go to that employee
          if (this.state.employees[0].SearchResultsList.length === 1){
            var abc = this.state.employees[0].SearchResultsList[0].ID;
            var access_token = sessionStorage.getItem("access_token");
      
              fetch(BASE_API_URL + '/api/v1/OrgChartAPI/AddRecentOrFavorite/' + abc + "/R", {
                method: 'POST',
                headers: {
                    'authorizationtoken': access_token,
                    'Origin': URL_UI_ORIGIN,
                    'Content-Type': 'application/json'
                },
              }).then((res) => {
                if (res.status != 200){
                  //window.location.href = "/Logout/Timeout";
                  TimeoutRequest();
                }
                return res.json();
              })
              .then(result => {
                //console.log('result is: ', JSON.stringify(result, null, 4));
                window.location.href = "/OrgChart/" + this.state.employees[0].SearchResultsList[0].ID;
              })
              .catch(error => {
                  console.log("error", error);
              });
              //console.log('result is: ', JSON.stringify(result, null, 4));
          }
          else
          {
            //refresh oam session and continue
            AuthenticateRequest();
          }

          $(".line-data").on('click', function (event) {
            var abc = $(this).attr("auth");
            var access_token = sessionStorage.getItem("access_token");
      
              fetch(BASE_API_URL + "/api/v1/OrgChartAPI/AddRecentOrFavorite/" + abc + "/R", {
                method: "POST",
                headers: {
                    'authorizationtoken': access_token,
                    "Origin": URL_UI_ORIGIN
                }
              })
              .then((res) => {
                if (res.status != 200){
                  //window.location.href = "/Logout/Timeout";
                  TimeoutRequest();
                }
                return res.json();
              })
              .then(result => {
                //console.log('result is: ', JSON.stringify(result, null, 4));
                window.location.href = "/OrgChart/" + $(this).attr("auth");
              })
              .catch(error => {
                  console.log("error", error);
              });
              //console.log('result is: ', JSON.stringify(result, null, 4));
          });

        }
      ).catch(error => {
          console.log("help: " + error)
      });
    }

    render() {
      return(
          <div><br />
            {this.state.employees.map(emp=>(
              <div key="searchResultsItem" className="main-wrap bg-direct-report-gray-adj top-border-container">
                <div className="container container-adj">
                {emp.SearchResultsList.length === 0 &&
                      <div className="row" style={{"padding-top":"50px"}}>
                        <div className="col-xs-12 col-sm-12 text-center">
                            <span className="recent-label">No Results Found</span>
                        </div>
                      </div>
                    }                                      

                  {emp.SearchResultsList.length > 1 &&
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 text-left pad-top-20">
                            <span className="recent-label">Search Results
                            {emp.SearchResultsList.length == 200 &&
                              <span>&nbsp;(Showing only 200 results. Please narrow your search.)</span>
                            }
                            {emp.SearchResultsList.length < 200 &&
                              <span>&nbsp;({emp.SearchResultsList.length})</span>
                            }
                            </span>
                        </div>
                      </div>
                    }

                      <div className="row">
                          <div className="col-xs-12 col-sm-12 text-center">
                            {emp.SearchResultsList.length > 1 &&
                                <div className="table-responsive admin-table">
                                <table id="gvMainTable" className="table default bg margin-none table-sorter">
                                    <thead>
                                      <tr className="orion-table-hdr">
                                        <th className='photo-column'></th>
                                        <th className='orion-table-hdr-td hidden-md'>Last Name</th>
                                        <th className='orion-table-hdr-td hidden-md'>First Name</th>
                                        <th className='orion-table-hdr-td show-on-md'>Name</th>
                                        <th className='orion-table-hdr-td hidden-md'>Title</th>
                                        <th className='orion-table-hdr-td hidden-sm'>Location</th>
                                        <th className='arrow-column'></th>
                                      </tr>
                                    </thead>
                                    {emp.SearchResultsList.length > 1 && 
                                    emp.SearchResultsList.map(e=>(
                                        <tbody key={e.ID}>
                                            <tr className="orion-table-tr line-data pointer" auth={e.ID}>
                                                <td className="orion-table-photo-td" valign="middle">
                                                    <div className="empl-img-size-tbl">
                                                        <div className="empl-img-tbl">
                                                          {e.PhotoString.length > 0 && (
                                                            <div className="image-cropper-tbl">
                                                              <OverlayTrigger trigger={["hover", "focus"]} placement="right" overlay={popoverTop(e.PhotoString)}>
                                                                  <img src={e.PhotoString} className="profile-pic-tbl" alt={e.FirstName} />
                                                              </OverlayTrigger>
                                                            </div>
                                                          )}

                                                          {e.PhotoString.length == 0 && (
                                                            <div className="initial-cropper-tbl">
                                                              {e.Initials}
                                                            </div>
                                                          )}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="orion-table-td hidden-md">
                                                    {e.LastName}
                                                </td>
                                                <td className="orion-table-td hidden-md">
                                                    {e.FirstName}
                                                </td>
                                                <td className="orion-table-td show-on-md">
                                                    <strong>{e.LastName}, {e.FirstName}</strong>
                                                    <br />
                                                    <span className='normal-font-weight'>{e.Title}</span>
                                                </td>
                                                <td className="orion-table-td hidden-md">
                                                    {e.Title}
                                                </td>
                                                <td className="orion-table-td hidden-sm">
                                                    {e.Location}
                                                </td>
                                                <td className="orion-table-arrow-td">
                                                    <img src={RightArrow} alt='More' />
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))
                                }

                                </table>
                            </div>
                            }

                          </div>
                        </div>
                    </div>
                </div>
                ))
              }
          </div>
        );
      }
  }

export default SearchResults
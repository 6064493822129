import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import $ from 'jquery';
//import ActiveUserId from "../components/emplid.js";
import { getConfig } from '../utils/env-utils';

import randomstring from "randomstring"
import CryptoJS from "crypto-js";
import { datadogRum } from '@datadog/browser-rum';


const OAM_LOGOUT = `${getConfig('OAM_LOGOUT')}`;

class Logout extends React.Component {
    constructor(props) {
      super(props);
      this.state={oam_response: []}
    }

    componentDidMount() {
      datadogRum.stopSessionReplayRecording();
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = OAM_LOGOUT;
    }

    render() {
      return(
          <div>

          </div>
        );
      }
  }

export default Logout
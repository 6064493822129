import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
//import ActiveUserId from "../components/emplid.js";
import { getConfig } from '../utils/env-utils';

import randomstring from "randomstring";
import CryptoJS from "crypto-js";
import { RefreshToken } from '../components/RefreshToken';

if (sessionStorage.getItem("codeVerifier") == null && sessionStorage.getItem("codeChallenge") == null){
    const codeVerifier = randomstring.generate(128);
    sessionStorage.setItem("codeVerifier", codeVerifier);
    const codeChallenge = CryptoJS.enc.Base64url.stringify(CryptoJS.SHA256(codeVerifier));
    sessionStorage.setItem("codeChallenge", codeChallenge);
}

var OAM_CODE = "";
const BASE_API_URL = `${getConfig('URL_ORG_API_BASE')}`;
const URL_UI_ORIGIN = `${getConfig('URL_UI_ORIGIN')}`;
const OAM_BASE_AUTHORIZE_URL = `${getConfig('OAM_AUTHORIZE_URL')}`;
const OAM_TOKEN_URL = `${getConfig('OAM_TOKEN_URL')}`;
const OAM_TOKEN_INFO_URL = `${getConfig('OAM_TOKEN_INFO_URL')}`;
const OAM_OAUTH_IDENTITY_DOMAIN = `${getConfig('OAM_OAUTH_IDENTITY_DOMAIN')}`;
const OAM_CLIENT_ID = `${getConfig('OAM_CLIENT_ID')}`;
const OAM_CLIENT_NAME = `${getConfig('OAM_CLIENT_NAME')}`;
const OAM_CLIENT_AUTH = `${getConfig('OAM_CLIENT_AUTH')}`;
const OAM_REDIRECT_URI = `${getConfig('OAM_REDIRECT_URI')}`;
// const OAM_CODE_VERIFIER = `${getConfig('OAM_CODE_VERIFIER')}`;
// const OAM_CODE_CHALLENGE = `${getConfig('OAM_CODE_CHALLENGE')}`;
const OAM_CODE_CHALLENGE_METHOD = `${getConfig('OAM_CODE_CHALLENGE_METHOD')}`;
const OAM_SCOPE = `${getConfig('OAM_SCOPE')}`;
const ENV = `${getConfig('ENV')}`;
const OAM_CODE_VERIFIER = sessionStorage.getItem("codeVerifier");
const OAM_CODE_CHALLENGE = sessionStorage.getItem("codeChallenge");
const OAM_AUTHORIZE_URL = OAM_BASE_AUTHORIZE_URL + OAM_CODE_CHALLENGE;

// var textString = '243721'; // Utf16-encoded string
// var words = CryptoJS.enc.Utf16.parse(textString); // WordArray object
// var base64 = CryptoJS.enc.Base64.stringify(words); // string: 'ADIANAAzADcAMgAx'
// alert(base64);
// var words = CryptoJS.enc.Base64.parse(base64);
// var textString2 = CryptoJS.enc.Utf16.stringify(words); // '243721'
// alert(textString2);


class Login extends React.Component {
    constructor(props) {
      super(props);
      this.state={oam_response: []}
    }

    componentDidMount() {
      //local
      //const challenge = pkceChallenge();
      //console.log(JSON.stringify(challenge));
      //alert("stop");
 
      if (ENV === 'local'){
        sessionStorage.setItem("access_token", "access_token");
        sessionStorage.setItem("refresh_token", "refresh_token");
        sessionStorage.setItem("APP_LOADED", 'Y');
        window.location.href = "/Home";
      }

      //check for OAM code in query string
      if (window.location.search !== "" ) {

        var getAllParams = window.location.search;
        var paramsArray = getAllParams.split("?code=");
        OAM_CODE = paramsArray[1];
        // console.log("LOGIN CODE: " + OAM_CODE);
        // console.log('START FETCH NOW');

        fetch(OAM_TOKEN_URL, {
            method: 'POST',
            headers: new Headers({
                'Authorization': "Basic " + OAM_CLIENT_AUTH,
                'x-oauth-identity-domain-name': OAM_OAUTH_IDENTITY_DOMAIN,
                'Origin': URL_UI_ORIGIN,
                'Content-Type': 'application/x-www-form-urlencoded'
            }),
            body: new URLSearchParams({
                'code': OAM_CODE,
                'grant_type': 'AUTHORIZATION_CODE',
                'redirect_uri': OAM_REDIRECT_URI,
                'response_type': 'token',
                'code_verifier': OAM_CODE_VERIFIER
            }),
            redirect: 'follow'
        })
            .then(res => res.json())
            .then(result => {
                //set access and refresh token in session and redirect to home
                var newData = this.state.oam_response.concat([result]);

                var access_token = newData[0]["access_token"];
                var refresh_token = newData[0]["refresh_token"];

                sessionStorage.setItem("access_token", access_token);
                sessionStorage.setItem("refresh_token", refresh_token);

                // console.log("access token is " + access_token);
                // console.log("now validate access token");
                sessionStorage.setItem("APP_LOADED", 'Y');
                if (sessionStorage.getItem("redirect_id") !== null && sessionStorage.getItem("redirect_id") !== ""){
                  window.location.href = "/redirect/" + sessionStorage.getItem("redirect_id");
                }
                else
                {
                  window.location.href = "/Home";
                }
            })
            .catch(error => {
                console.log('EXCEPTION ERROR - ', error);
                // if error on getting token, then return to OAM login screen
                window.location.href = OAM_AUTHORIZE_URL;
            });

        }
      else
      {
        //NO CODE FROM OAM. CHECK FOR ACCESS TOKEN IN SESSION
        var access_token = sessionStorage.getItem("access_token");

        if (access_token !== null && access_token !== ""){
          //has access token. let's verify and make sure it's good
          var myHeaders = new Headers();
          myHeaders.append("x-oauth-identity-domain-name", OAM_OAUTH_IDENTITY_DOMAIN);
          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
          
          var urlencoded = new URLSearchParams();
          urlencoded.append("access_token", access_token);

          var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: urlencoded,
              redirect: 'follow'
          };

          fetch(OAM_TOKEN_INFO_URL, requestOptions)
              .then(response => {
                  if (response.status != 200){
                      //error validating access token. Let's try to refresh token and see
                      RefreshToken();
                  }
                  return response.json();
              })
              .then(result => {
                  var stringy = JSON.stringify(result);  
                  var jsonObj = JSON.parse(stringy);
                  var rem_exp = jsonObj["rem_exp"];
                  sessionStorage.setItem("rem_exp", rem_exp);
                  var exp_time = new Date();
                  exp_time.setSeconds(exp_time.getSeconds() + parseInt(rem_exp));
                  sessionStorage.setItem("timeout_time", exp_time);
                  sessionStorage.setItem("APP_LOADED", 'Y');

                  if (sessionStorage.getItem("redirect_id") !== null && sessionStorage.getItem("redirect_id") !== ""){
                    window.location.href = "/redirect/" + sessionStorage.getItem("redirect_id");
                  }
                  else
                  {
                    window.location.href = "/Home";
                  }
              })
              .catch(error => {
                  console.log('validating access token error', error);
                  //error validating refresh token. Login again
                  window.location.href = OAM_AUTHORIZE_URL;    
              });
        }
        else
        {
            console.log("in validatetoken - no access token");
            //no refresh token. so now we redirect back to login page
            window.location.href = OAM_AUTHORIZE_URL;
        }




        // OLD CODE HERE
        // var refresh_token = sessionStorage.getItem("refresh_token");
        // var access_token = sessionStorage.getItem("access_token");

        // if (refresh_token != null && refresh_token != ""){
        //     //has refresh token. call refresh api and get updated access token
        //     var myHeaders = new Headers();
        //     myHeaders.append("Authorization", "Basic " + OAM_CLIENT_AUTH);
        //     myHeaders.append("x-oauth-identity-domain-name", OAM_OAUTH_IDENTITY_DOMAIN);
        //     myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        //     //myHeaders.append("Cookie", "oam_int_devqa=rd4o00000000000000000000ffff0aa41d45o4443");

        //     var urlencoded = new URLSearchParams();
        //     urlencoded.append("refresh_token", refresh_token);
        //     urlencoded.append("grant_type", "REFRESH_TOKEN");
        //     urlencoded.append("redirect_uri", OAM_REDIRECT_URI);
        //     urlencoded.append("response_type", "token");
        //     urlencoded.append("code_verifier", OAM_CODE_VERIFIER);

        //     var requestOptions = {
        //         method: 'POST',
        //         headers: myHeaders,
        //         body: urlencoded,
        //         redirect: 'follow'
        //     };

        //     fetch(OAM_TOKEN_URL, requestOptions)
        //         .then(response => response.text())
        //         .then(result => {
        //             var newData = this.state.oam_response.concat([result]);
        //             // console.log("next two are responses from the refresh token function");
        //             // console.log(newData);
        //             // console.log(newData[0]);
        //             var access_token = newData[0]["access_token"];
        //             var refresh_token = newData[0]["refresh_token"];
                    
        //             sessionStorage.setItem("access_token", access_token);
        //             sessionStorage.setItem("refresh_token", refresh_token);
        //             sessionStorage.setItem("APP_LOADED", 'Y');
                    
        //             if (sessionStorage.getItem("redirect_id") !== null && sessionStorage.getItem("redirect_id") !== ""){
        //               window.location.href = "/redirect/" + sessionStorage.getItem("redirect_id");
        //             }
        //             else
        //             {
        //               window.location.href = "/Home";
        //             }
        //         })
        //     .catch(error => {
        //         console.log('error', error);
        //         //error validating refresh token. Login again
        //         window.location.href = OAM_AUTHORIZE_URL;    
        //     });
        // }
        // else
        // {
        //     console.log("no refresh token");
        //     //no refresh token. so now we redirect back to login page
        //     window.location.href = OAM_AUTHORIZE_URL;
        // }
      }
    }

    render() {
      return(
          <div>

          </div>
        );
      }
  }

export default Login